<script setup lang="ts">
import { UI05Button, UI05Modal } from 'ui-05'
import { useSaveCardModal } from '~~/store/modal/saveCard'

const { $error, $api } = useNuxtApp()

const { active, disable, cardID } = useSaveCardModal()
const isAgree = ref(true)

const remove = useActionEntity($api.user.removePaymentCard, {
  params: {
    id: cardID,
  },
  onSuccess: () => {
    disable()
  },
  onError: ({ serialized }) => {
    if (serialized)
      new $error.simple({ ...serialized })
    disable()
  },
})

function onClose() {
  if (!isAgree.value)
    remove.request()

  else
    disable()
}
</script>

<template>
  <UI05Modal
    title=""
    class="save-card__modal"
    max-width="360px"
    overlay-theme="light"
    :show="active"
    @close="disable"
  >
    <template #default>
      <div class="modal__content">
        <img
          class="content__img"
          src="./asset/checkbox.png"
          alt="Иконка чекбокса"
        >
        <h1 class="content__title">
          Заказ оформлен
        </h1>
        <p class="content__desc">
          Мы сохраним карту для
          следующих заказов
        </p>
        <div class="content__confirm">
          <ToggleSwitch v-model="isAgree" />
          <p class="confirm__desc">
            Использовать карту для
            <br>
            оплаты следующих заказов
          </p>
        </div>
        <UI05Button
          class="content__button"
          fluid
          theme="red"
          size="49"
          @click="onClose"
        >
          Продолжить
        </UI05Button>
      </div>
    </template>
  </UI05Modal>
</template>

<style scoped lang="postcss">
.modal__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -12px;

  .content__img {
    align-self: center;
    width: 128px;
    height: 128px;

  }

  .content__title {
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    color: #2C2E33;

    @media (max-width: 800px) {
      font-size: 19px;
      line-height: 21px;
      color: #000;
    }
  }

  .content__desc {
    max-width: 204px;
    margin-top: 12px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #181818;

    @media (max-width: 800px) {
      max-width: 160px;
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #A2A2A2;
    }
  }

  .content__confirm {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    margin-top: 20px;

    @media (max-width: 800px) {
      margin-top: 15px;
    }

    .confirm__desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #1A1A1A;
    }
  }

  .content__button {
    margin-top: 25px;

    @media (max-width: 800px) {
      margin-top: 17px;
      font-size: 16px;
    }
  }
}
</style>
