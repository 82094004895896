export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1.0"},{"name":"apple-itunes-app","content":"app-id=1539023015, app-argument=https://darkstore.05.ru/"},{"hid":"description","name":"description","content":"Даркстор - сервис доставки продуктов за 30 минут."}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/vue/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/vue/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/vue/favicon-16x16.png"},{"rel":"preload","as":"font","type":"font/woff2","href":"/vue/font/Ubuntu-Italic.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"/vue/font/Ubuntu-Medium.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"/vue/font/Ubuntu-Regular.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"/vue/font/ubuntu-ruble-italic.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"/vue/font/ubuntu-ruble-medium.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"/vue/font/ubuntu-ruble-regular.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"/vue/font/Ubuntu-Mono-Regular.woff2","crossorigin":"anonymous"}],"style":[],"script":[{"id":"metrika","innerHTML":"(function(m,e,t,r,i,k,a){\n    m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n    m[i].l=1*new Date();\n    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n    (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");"}],"noscript":[],"htmlAttrs":{"lang":"ru"},"title":"Даркстор"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false