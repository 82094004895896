import Thumbnail from '../Thumbnail'
import CategoryBase from '../CategoryBase'
import Price from '../Price'
import ProductStatus from './ProductStatus'
import type { Product } from '~/type/product/Product'

interface ProductResponse {
  [key: string]: any
  ATTRIBUTES?: any[]
  IMAGES_THUMBNAILS?: any[]
}

export default function (el: ProductResponse): Product {
  const quantity = +el.QUANTITY || 1
  /* С API превью приходят без заглавной фотки, поэтому мы её туда помещаем сами, вручную */
  const image = el.IMAGE ?? ''
  const thumbnail = Thumbnail(el.THUMBNAILS)
  const thumbnailArray = el.IMAGES_THUMBNAILS?.length
    ? el.IMAGES_THUMBNAILS.map((el: any) => {
      return {
        image: el.image,
        thumbnail: Thumbnail(el.THUMBNAILS),
      }
    })
    : []
  thumbnailArray.unshift({
    image,
    thumbnail,
  })

  return {
    id: +el.ID,
    name: el.NAME,
    image,
    thumbnail,
    thumbnailArray,
    limit: el?.AMOUNT?.AVAILABLE_TO_ORDER ?? el?.AMOUNT?.IN_CURRENT_CITY,
    price: Price(el?.PRICES),
    isUnavailable: !el.IS_AVAILABLE,
    inFavorites: el.IN_FAVORITES,
    oldQuantity: el?.OLD_QUANTITY,
    isGift: el.IS_GIFT,
    status: el.STATUS ? ProductStatus(el.STATUS) : {},
    cart: el.IN_BASKET || false,
    path: el.DETAIL_PAGE_PATH,
    code: el.ATTRIBUTES?.length ? el?.ATTRIBUTES.find((item: any) => item?.TYPE === 'CODE')?.VALUE : undefined,
    category: el.SECTION ? CategoryBase(el.SECTION) : undefined,
    quantity,
  }
}
