<script setup lang="ts">
import type { Product } from '~/type/product/Product'
import empty from '~/components/ImgLazy/asset/empty.png'

interface Props {
  product: Product
}
const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'product-click', value: { id: number; path?: string }): void
}>()

const previousAmount = ref(props.product?.changedAmount || props.product?.oldQuantity)
const isChanged = computed(() => (props.product.oldQuantity && props.product.quantity < props.product.oldQuantity)
|| (typeof previousAmount.value === 'number' && props.product.quantity))
</script>

<template>
  <div class="order-product">
    <div
      class="order-product__img-wrap"
      :class="[{ 'order-product__img-wrap_not-available': product.isUnavailable }]"
      @click="emit('product-click', { id: product.id, path: product.path })"
    >
      <img
        class="order-product__img"
        :src="product.thumbnail.x1 ? product.thumbnail.x1 : empty"
        :srcset="`${product.thumbnail.x2 ? product.thumbnail.x2 : empty} 2x`"
        :alt="product.name"
      >
    </div>
    <div
      class="order-product__text"
      @click="emit('product-click', { id: product.id, path: product.path })"
    >
      <div class="order-product__title p p_crop-2 link link_wu" :class="[{ 'order-product__title_not-available': product.isUnavailable }]">
        {{ product.name }}
      </div>
      <div v-if="product.quantity" class="order-product__price-wrap">
        <Price v-if="product.price && !product.isUnavailable" :price="product.price" />
        <div v-if="!(props.product.weight?.shipped && props.product.weight?.expected) && !product.isUnavailable" class="order-product__quantity-wrap">
          <div class="order-product__quantity" :class="[{ 'order-product__quantity_changed': isChanged }]">
            {{ product.quantity }} шт
          </div>
          <div
            v-if="isChanged"
            class="order-product__quantity"
            :class="[{ 'order-product__quantity_changed-item': isChanged }]"
          >
            {{ previousAmount }} шт
          </div>
        </div>
      </div>
      <span v-if="props.product.weight?.shipped && props.product.weight?.expected && (props.product.weight?.shipped !== props.product.weight?.expected)" class="order-product__changed-text">
        {{ `Изменился вес товара ${product.weight?.expected} ` }}
        <img
          src="./asset/arrow-right.svg"
          alt="arrow"
          class="mr-3 ml-3"
        >
        {{ product.weight?.shipped }}
      </span>
      <span v-else-if="isChanged" class="order-product__changed-text">
        Количество позиций изменилось
      </span>
      <span v-else-if="product.limit === 0 || product.quantity === 0" class="order-product__changed-text">
        Раскупили
      </span>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.order-product {
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  &__img-wrap {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #f2f2f2;
    border-radius: 5px;

    &_not-available {
      opacity: 0.4;
    }
  }

  &__changed-text {
    display: flex;
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
    color: #E30613;
  }

  &__img {
    max-height: 50px;
    mix-blend-mode: multiply;
  }

  &__text {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
  }

  &__title {
    max-width: 275px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 18px;

    &_not-available {
      opacity: 0.4;
    }
  }

  &__price-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__quantity-wrap {
    display: flex;
  }

  &__quantity {
    font-size: 14px;
    line-height: 20px;
    color: #ccc;

    &_changed {
      color: #000;
    }

    &_changed-item {
      position: relative;
      margin-left: 8px;

      &::before {
        position: absolute;
        top: 60%;
        left: 1px;
        width: 34px;
        height: 1px;
        content: '';
        background-color: #e30613;
        transform: rotate(-17deg);
      }
    }
  }
}
</style>
