<script setup lang="ts">
import debounce from 'lodash.debounce'
import { UI05Button, UI05Tooltip } from 'ui-05'
import cloneDeep from 'lodash.clonedeep'
import pointInPolygon from 'point-in-polygon'
import mitt from 'mitt'
import { formatAddress } from '@artmizu/utils'
import { useAddressStore } from '~/store/address'
import { useResizeStore } from '~/store/resize'
import { useChooseAddressStore } from '~/store/modal/chooseAddress'
import { deliveryAvailableStore } from '~/store/modal/deliveryAvailable'
import type { Address } from '~/type/Address'
import type { MapsArea } from '~/type/MapsArea'
import checkCartChanges from '~/helper/checkCartChanges'
import { useNotWorkingTimeModal } from '~/store/modal/notWorkingTime'

const emit = defineEmits<{ (event: 'select-address', value: Address): void }>()
const { $error, $api, $emitter } = useNuxtApp()
const deliveryAvailableModalStore = deliveryAvailableStore()
const notWorkingTimeModalStore = useNotWorkingTimeModal()
const emitter = mitt()
const addressInput = ref('')
const value = ref<Address>()
const isAddressSelect = ref(false)
const showList = ref(false)
const hintsError = ref(false)
const deliveryTime = ref<number>()
const list = ref<Address[]>([])

const deliveryZone = await useDataEntity('delivery-zone', $api.geo.getDeliveryZone, {
  params: {},
})

const userAddressList = computed(() => useAddressStore().history)
const currentAddress = computed(() => useAddressStore().current)

const isInAreaAddress = computed(() => deliveryZone.data.value?.polygons.some(element => value.value && isInArea(value.value.lat, value.value.lon, element)))
/**
 * Проверяет выбрал ли пользователь правильный адрес т.е выбрал название улицы или микрорайона и номер дома.
 */
const isIncorrectAddress = computed(() => {
  return !isAddressSelect.value || !isInAreaAddress.value || (!value?.value?.street && !value?.value?.district) || !value?.value?.house
})

const isMobile = computed(() => useResizeStore().isMobile)
const greenBadgeText = computed(() => {
  if (isAddressSelect.value) {
    if (isInAreaAddress.value && !isIncorrectAddress.value)
      return `Доставим через ${deliveryTime.value} минут`
    else
      return 'По этому адресу нет доставки'
  }
  else {
    return 'Зона доставки'
  }
})
const userAddressListSliced = computed(() => {
  /* Глубоко копирую, потому что если просто присвоить значение переменной, ругается на то, что меняю состояние pinia вне мутации.  */
  const userAddressListLocal = cloneDeep(userAddressList.value)
  userAddressListLocal.reverse()
  return userAddressListLocal.slice(0, 2)
})
const hintAddressesCount = computed(() => isMobile.value ? 5 : 10)
const currentStreet = computed(() => value.value?.formatted ?? 'Адрес доставки')

const request = debounce(requestFunc, 350)
const { set: setAddressToStore } = useAddressStore()
const { disable: disableMap } = useChooseAddressStore()
const afterAddressSelect = useChooseAddressStore().afterAddressSelect

onMounted(() => $emitter.emit('open-modal-choose-address'))

function onAddressDefinition(data: Address) {
  onMapClick(data)
  if (value.value?.lat && value.value.lon) {
    emitter.emit('changeMarkerPos', { lat: value.value?.lat, lng: value.value?.lon })
  }
  else {
    UI05Tooltip({
      type: 'warning',
      title: 'Предупреждение',
      description: 'Не удалось определить адрес',
    })
  }
}

function onFocus(payload: Event) {
  const currentTarget = payload.target as HTMLInputElement
  if (currentTarget.value) {
    request(currentTarget.value)
    showList.value = true
  }
}

function onArrowBackClick() {
  showList.value = false
  if (value.value?.lat && value.value?.lon) {
    isAddressSelect.value = true
    addressInput.value = getValue(value.value)
  }
}

function getValue(el: Address) {
  return formatAddress(el) || ''
}

function onMapClick(data: Address) {
  try {
    value.value = data
    addressInput.value = getValue(data)
    showList.value = false
    isAddressSelect.value = true
  }
  catch (error) {
    new $error.simple({
      name: 'Выберите корректный адрес',
      description: 'Скорее всего вы не выбрали номер дома',
      message: '',
    })
  }
}

function isInArea(lat: number, lon: number, deliveryPolygon: MapsArea) {
  const tmp = deliveryPolygon.polygon.map(latlngArr => Object.values(latlngArr).map(el => Number(el)))
  deliveryTime.value = deliveryPolygon.deliveryTime
  return pointInPolygon([lat, lon], tmp)
}

function onListElClick(el: Address) {
  showList.value = false
  isAddressSelect.value = true
  value.value = el
  addressInput.value = getValue(el)
  emitter.emit('changeMarkerPos', { lat: el.lat, lng: el.lon })
}

function onPastAddressClick(data: Address) {
  value.value = data
  isAddressSelect.value = true
  addressInput.value = ''
  emitter.emit('changeMarkerPos', { lat: data.lat, lng: data.lon })
}

async function setAddress() {
  if (value.value) {
    emit('select-address', value.value)
    const currentAreaCode = useAddressStore().current?.areaCode
    const areaCode = deliveryZone.data.value?.polygons.find(element => isInArea(value.value!.lat, value.value!.lon, element))?.code

    try {
      await $api.map.setAddress({ ...value.value, areaCode })
      setAddressToStore({ ...value.value, areaCode })
      if (typeof afterAddressSelect === 'function')
        await afterAddressSelect()
      /*
        если зона доставки отличается то перезагружаемся, чтобы
        получить новые товары в соответсвии с складами
        и обновить данные корзины
      */
      if (currentAreaCode !== areaCode) {
        // нужно подождать изменения адреса
        nextTick(() => {
          notWorkingTimeModalStore.setIsShowModal(false)
          location.reload()
        })
      }
      else {
        checkCartChanges()
      }
      disableMap()
    }
    catch (e) {
      new $error.simple({
        name: 'Введите корректный адрес',
        message: 'Скорее всего вы не выбрали номер дома',
      })
    }
  }
}

function onInput(payload: Event) {
  const currentTarget = payload.target as HTMLInputElement
  showList.value = true
  if (currentTarget.value)
    request(currentTarget.value)
  else showList.value = false
}

async function requestFunc(val: string) {
  try {
    list.value = await $api.geo.getHintsYandex({
      query: val,
      count: hintAddressesCount.value,
      // зону получаем из списка полигонов с бэка
      bbox: `${deliveryZone.data.value?.maxMinCoords?.lon.min},${deliveryZone.data.value?.maxMinCoords?.lat.min}~${deliveryZone.data.value?.maxMinCoords?.lon.max},${deliveryZone.data.value?.maxMinCoords?.lat.max}`,
    })
  }
  catch (error) {
    try {
      new $error.base({
        message: 'Не удалось получить подсказки к запросу через яндекс',
        native: error,
      })
      list.value = await $api.geo.getHints({
        query: val,
        count: hintAddressesCount.value,
        locations: [{ city: 'Каспийск' }],
      })
    }
    catch (e) {
      hintsError.value = true
      new $error.base({
        message: 'Не удалось получить подсказки к запросу через дадату',
        native: e,
      })
    }
  }
}

function onOverlayClick(payload: Event) {
  const currentTarget = payload.target as HTMLElement
  const isOverlay = currentTarget.classList.contains('delivery-map')
  if (isOverlay)
    disableMap()
}

function onSendDeliveryAvailable() {
  if (value.value?.formatted) {
    disableMap()
    deliveryAvailableModalStore.enable(value.value?.formatted)
  }
  $emitter.emit('click-notify-delivery-available')
}
</script>

<template>
  <div
    data-e2e="choose-address-map"
    class="delivery-map"
    @mousedown="onOverlayClick"
  >
    <div class="delivery-map__wrapper">
      <div class="sidebar">
        <div class="sidebar__header">
          <div class="sidebar__title">
            Выберите адрес доставки
          </div>
          <div class="search search_desktop">
            <input
              v-model.trim="addressInput"
              type="text"
              class="search__input"
              data-e2e="search-input__desktop"
              placeholder="Введите адрес"
              @input="onInput"
              @focus="onFocus"
            >
            <span class="search__icon search__icon_search icon-search icon-search_gray" />
          </div>
        </div>
        <div class="sidebar__content">
          <div
            v-if="!showList"
            class="user-address-desktop"
          >
            <template v-if="!isAddressSelect && userAddressListSliced.length">
              <div class="user-address-desktop__title">
                Мои адреса
              </div>
              <div
                v-for="address in userAddressListSliced"
                :key="address.street"
                class="user-address-desktop__el"
                @click="onListElClick(address)"
              >
                <div class="user-address-desktop__el-name">
                  {{ getValue(address) }}
                </div>
              </div>
            </template>
            <div v-else />
          </div>
          <template v-else>
            <div v-if="hintsError">
              При поиске произошла ошибка :( Попробуйте выбрать адрес на карте
            </div>
            <template v-else-if="list && list.length === 0">
              <div class="grey-text p pt-12 pb-11">
                Адрес не найден, либо он находится вне зоны доставки, либо нужно скорректировать запрос
              </div>
            </template>
            <div
              v-else
              class="address-list"
              data-e2e="address-search-result-desktop"
            >
              <div
                v-for="(el, index) in list"
                :key="index"
                class="address-item"
                @click="onListElClick(el)"
              >
                <div class="address-item__name">
                  {{ getValue(el) }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="sidebar__footer">
          <MapNote class="sidebar__note" />
          <UI05Button
            size="49"
            :disabled="isIncorrectAddress"
            fluid
            data-e2e="submit-address-btn__desktop"
            @click="setAddress"
          >
            Доставить сюда
          </UI05Button>
        </div>
      </div>
      <div class="content">
        <div class="content__header">
          <div
            class="delivery-map__close delivery-map__close_mobile"
            @click="disableMap"
          />
          <h4 class="h4 content__title">
            {{ currentStreet }}
          </h4>
          <template v-if="isAddressSelect">
            <span
              class="badge badge_green lh-16 b"
              :class="isIncorrectAddress ? 'badge_red' : 'badge_green'"
            >{{
              greenBadgeText
            }}</span>
          </template>
          <template v-else>
            <span class="badge badge_green lh-16 b">{{ greenBadgeText }}</span>
          </template>
        </div>
        <div
          class="content__footer"
          :class="[
            {
              'content__footer_address-select': isAddressSelect,
            },
          ]"
        >
          <div class="content__location-button">
            <FindAddress @on-address-definition="onAddressDefinition" />
          </div>
          <MapNote class="content__note" />
          <div class="mb-10">
            <div
              v-show="showList"
              class="address-list"
              data-e2e="address-search-result-mobile"
            >
              <div v-if="hintsError">
                При поиске произошла ошибка :( Попробуйте выбрать адрес на карте
              </div>
              <template v-if="list && list.length === 0">
                <div class="grey-text pt-7 pb-11">
                  Адрес не найден, либо он находится вне зоны доставки, либо нужно скорректировать запрос
                </div>
              </template>
              <template v-else>
                <div
                  v-for="(el, index) in list"
                  :key="index"
                  class="address-item"
                  @click="onListElClick(el)"
                >
                  <div class="address-item__name">
                    {{ getValue(el) }}
                  </div>
                  <div
                    v-if="el.region"
                    class="address-item__region"
                  >
                    {{ el.region }}
                  </div>
                </div>
              </template>
            </div>
            <div class="search search_mobile">
              <span
                class="search__icon search__icon_arrow-left icon-left-arrow icon-left-arrow_black"
                @click="onArrowBackClick"
              />
              <input
                v-model.trim="addressInput"
                type="text"
                class="search__input"
                data-e2e="search-input__mobile"
                placeholder="Введите адрес"
                @input="onInput"
                @focus="onFocus"
              >
              <span class="search__icon search__icon_search icon-search icon-search_black" />
            </div>
          </div>
          <div
            v-if="userAddressListSliced.length"
            class="user-address-mobile"
          >
            <div
              v-for="address in userAddressListSliced"
              :key="address.street"
              class="user-address-mobile__item"
              :class="[
                {
                  'user-address-mobile__item_active': address.street === value?.street && address.house === value?.house,
                },
              ]"
              @click="onPastAddressClick(address)"
            >
              {{ getValue(address) }}
            </div>
          </div>
          <div class="content__submit-button">
            <UI05Button
              size="49"
              :disabled="isIncorrectAddress"
              fluid
              data-e2e="submit-address-btn__mobile"
              @click="setAddress"
            >
              {{ (value?.street || value?.district) && value?.house ? 'Доставить сюда' : 'Выберите улицу и дом' }}
            </UI05Button>
          </div>
        </div>
        <div class="map">
          <div v-if="!isInAreaAddress && (value?.street || value?.district) && value?.house" class="delivery-note">
            <p class="delivery-note__text">
              Сообщить вам когда доставка будет доступна?
            </p>
            <button class="delivery-note__btn link link_blue link_wu" @click="onSendDeliveryAvailable">
              Да
            </button>
            <img
              src="./asset/vector.svg"
              alt="Сообщить о возможности доставки"
              class="delivery-note__point"
            >
          </div>
          <DeliveryMap
            :zone="deliveryZone?.data?.value?.polygons"
            height="100%"
            :zoom="17"
            :is-not-scroll="false"
            :emitter="emitter"
            :center="
              currentAddress
                ? [currentAddress?.lat, currentAddress?.lon]
                : undefined"
            @change="onMapClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.delivery-note {
  position: absolute;
  top: calc(30% - 7px);
  left: 50%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 12px;
  background-color: #fff;
  border-radius: 10px;
  transform: translateX(-50%);

  &__text {
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
    border-bottom: 1px solid #F1F1F1;
  }

  &__btn {
    font-size: 16px;
    background-color: transparent;
    border: none;
  }

  &__point {
    position: absolute;
    bottom: -7px;
    left: 50%;
    width: 15px;
    height: 7px;
    transform: translateX(-50%);
  }

  @media (max-width: 800px) {
    top: calc(34% - 3px);
  }
}

.delivery-map {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-above-modal);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.88);
  opacity: 1;
  will-change: transform, opacity;

  &__wrapper {
    display: flex;
    width: 100%;
    max-width: 1120px;
    height: 80%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgb(0 0 0 / 9%);
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3; /* Чтобы перекрыть карту */
    width: 28px;
    height: 28px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
    background-image: svg-load('./asset/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px;
    backdrop-filter: blur(15px);
    border-radius: 50%;
  }

  @media (max-width: 800px) {
    padding: 0;

    &__wrapper {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  padding: 20px 15px;
  background-color: #fff;

  &__header {
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
  }

  &__footer {
    flex-shrink: 0;
    margin-top: 5px;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
  }
  @media (max-width: 800px) {
    display: none;
  }
}

.content {
  position: relative;
  display: flex;
  flex-grow: 1;

  &__header,
  &__footer {
    position: absolute;
    display: block;
    width: 100%;
    transition: top, ease-in-out;
  }

  &__header {
    top: 0;
    z-index: 2;
    padding-top: 45px;
    text-align: center;
    transition-duration: 0.4s;
  }

  &__title {
    max-width: calc(100% - 100px);
    margin: 0px auto 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__submit-button {
    background-color: rgb(255 255 255 / 89%);
    transition: opacity 0.3s ease-in-out;
  }

  &__location-button {
    display: none;
  }

  @media (max-width: 800px) {

    &__footer {
      bottom: 0;
      z-index: 3;
      padding: 0 15px 20px;
    }

    &__location-button {
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
    }
  }
}

.map {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ecebe8;
}

.badge {
  padding: 4px 10px;
  color: #fff;
  border-radius: 5px;
  opacity: 0.9;

  &_green {
    background-color: #25aa6d;
  }

  &_red {
    background-color: #e30613;
  }
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  &_mobile {
    display: none;
  }

  &__input {
    display: block;
    flex-grow: 1;
    width: 100%;
    height: 39px;
    padding-right: 20px;
    font-size: 14px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;

    &::-webkit-input-placeholder {
      color: #818181;
    }

    &::-moz-placeholder {
      color: #818181;
    }

    &:-ms-input-placeholder {
      color: #818181;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &_search {
      right: 15px;
      background-size: 16px;
    }

    &_arrow-left {
      left: 12px;
      display: none;
      width: 13px;
      height: 13px;
    }
  }

  @media (max-width: 800px) {
    border: 0px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

    &_desktop {
      display: none;
    }

    &_mobile {
      display: block;
    }
  }
}

.address-list {
  height: 100%;
  padding: 5px 15px;
  margin: 0 -15px;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 800px) {
    max-height: 55vh;
    padding: 0 15px px;
    margin: 0;
    background-color: #fff;
    transform: translateY(2px);
  }
}

.address-item {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f3f3f3;

  &:hover {
    padding: 10px 30px 10px 30px;
    margin: 0 -15px;
    background-color: #fffae5;
  }

  &__name {
    font-size: 14px;
    line-height: 18px;
  }

  &__region {
    font-size: 12px;
    line-height: 17px;
    color: #818181;
  }
  @media (max-width: 800px) {
    padding: 12px 0;

    &:last-child {
      padding-bottom: 15px;
    }

    &:hover {
      padding: 12px 15px;

      &:last-child {
        padding-bottom: 15px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &__name {
      font-weight: 500;
    }
  }
}

.user-address-mobile {
  padding-top: 3px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.97);
  border-radius: 4px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  transition: opacity 0.3s ease-in-out;

  &__item {
    padding: 10px 15px;
    cursor: pointer;
    background-image: svg-load('./asset/arrow-right.svg', fill=#000);
    background-repeat: no-repeat;
    background-position: center right 15px;
    background-size: 6px 10px;
    border-bottom: 1px solid #f3f3f3;

    &_active {
      color: #eb0010;
      background-image: svg-load('./asset/arrow-right.svg', fill=#eb0010);
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.user-address-desktop {
  padding-top: 15px;

  &__title {
    padding-bottom: 5px;
    font-size: 12px;
    line-height: 14px;
    color: #808080;
    border-bottom: 1px solid #f3f3f3;
  }

  &__el {
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid #f3f3f3;

    &:hover {
      padding: 10px 15px 10px 15px;
      margin: 0 -15px;
      background-color: #fffae5;
    }

    &-name {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
