import ProductSerializer from '~/serializer/product/Product'
import SortSerializer from '~/serializer/Sort'
import MetaBlockSerializer from '~/serializer/MetaBlock'
import CategorySerializer from '~/serializer/CategoryBase'
import BrandSerializer from '~/serializer/Brand'
import PaginationSerializer from '~/serializer/Pagination'
import getBreadcrumbs from '~/helper/getBreadcrumbs'
import type { Category } from '~/type/Category'
import convertCategories from '~/helper/convertCategories'

interface CatalogResponse {
  [key: string]: any
  SECTION_NAV_CHAIN_BLOCK: any[]
  SECTION_TREE_BLOCK?: {
    [key: string]: any
    CHILDREN?: any[]
  }
  PRODUCTS_BLOCK?: {
    [key: string]: any
    ITEMS?: any[]
  }
  BRANDS_BLOCK?: any[]
  FILTERS_BLOCK?: {
    SORT_TYPES?: any[]
  }
  path?: string
}

export default function (data: CatalogResponse): {
  meta: ReturnType<typeof MetaBlockSerializer>
  title: string
  id: string
  count: number
  brand: ReturnType<typeof BrandSerializer>[]
  pagination: ReturnType<typeof PaginationSerializer>
  sort: ReturnType<typeof SortSerializer>[]
  list: ReturnType<typeof ProductSerializer>[]
  parentCategoryPath: string
  breadcrumbList: Category[]
  tagList: Category[]
} {
  const saleMetaData = {
    category: {
      title: 'Акции и скидки на продукты с доставкой в Даркстор 05ру',
      description: 'На данной странице вы сможете найти все товары, которые участвуют в акциях в Даркстор 05ru',
    },
    element: {
      title: '',
      description: '',
    },
  }
  const lastCategoryID = getIdLastCategory(data?.SECTION_NAV_CHAIN_BLOCK)
  const category = data?.SECTION_NAV_CHAIN_BLOCK?.[0] && CategorySerializer(data.SECTION_NAV_CHAIN_BLOCK[0])

  /**
   * Возвращает id последней категории
   */
  function getIdLastCategory(data: any): string {
    return data?.[0]?.CHILDREN?.length ? getIdLastCategory(data[0].CHILDREN) : data?.[0]?.ID
  }

  return {
    // если страница скидочная, то проставляем сами title и мета теги, т.к на данный момент они не приходят с бэка
    meta: data.sale ? saleMetaData : MetaBlockSerializer(data?.META_BLOCK),
    title: data?.SECTION_TREE_BLOCK?.NAME,
    id: lastCategoryID,
    count: data?.PRODUCTS_BLOCK?.COUNT,
    brand: data?.BRANDS_BLOCK ? data.BRANDS_BLOCK.map((el: any) => BrandSerializer(el)) : [],
    pagination: PaginationSerializer(data?.PRODUCTS_BLOCK?.PAGINATION),
    sort: data?.FILTERS_BLOCK?.SORT_TYPES?.map((el: any) => SortSerializer(el)) || [],
    list: data?.PRODUCTS_BLOCK?.ITEMS?.map((el: any) => ProductSerializer(el)) || [],
    parentCategoryPath: data?.SECTION_TREE_BLOCK?.SECTION_PAGE_PATH ?? '',
    breadcrumbList: category ? getBreadcrumbs(category, data.path!) : [],
    tagList: data?.SECTION_TREE_BLOCK?.CHILDREN?.length ? convertCategories(data?.SECTION_TREE_BLOCK?.CHILDREN) : [],
  }
}
