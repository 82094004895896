import { stringify } from 'qs'
import { getFormData } from '@artmizu/utils'
import { getAbortController } from '~/helper/getAbortController'
import OrderSerializer from '~/serializer/Order'
import OrderCheckoutSerializer from '~/serializer/OrderCheckout'
import PaginationSerializer from '~/serializer/Pagination'
import CreatedOrder from '~/serializer/CreatedOrder'
import type { NewOrder } from '~/type/order/NewOrder'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import type Evaluation from '~/type/order/Evaluation'

export default {
  async get({ page, size }: { page?: number; size: number }) {
    const { $axios } = useNuxtApp()

    const { data } = await $axios.get<APIRequestSuccess<{ ITEMS: any[] }>>(`/api/v1/darkstore/user/orders?${stringify({ page, size })}`)

    return {
      list: data.result.ITEMS?.map((el: any) => OrderSerializer(el)) || [],
      pagination: PaginationSerializer(data.result?.PAGINATION || null),
      sort: [],
    }
  },

  async getByID(id: string | number) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.get(`/api/v1/darkstore/user/orders?${stringify({ order_id: id })}`)

    if (data.result?.ITEMS === null)
      return null

    return OrderSerializer(data.result)
  },

  async create(newOrder: NewOrder) {
    const { $axios } = useNuxtApp()

    const { data } = await $axios.post(
      '/api/v1/darkstore/order',
      {
        APARTMENT: newOrder.apartment,
        ENTRANCE: newOrder.entrance,
        FLOOR: newOrder.floor,
        INTERCOM: newOrder.intercom || '-', // чтобы апи не ругалось и не требовать от пользователя левую инфу,
        PRODUCT_AVAILABILITY_CONDITION_ID: newOrder.productAvailabilityConditionID,
        COMMENT: newOrder.commentWarehouse,
        COMMENT_FOR_COURIER: newOrder.commentCourier,
        REBILL_ID: newOrder.paymentCard?.rebillId, // ID сохраненной карты пользователя
        LEAVE_AT_THE_DOOR: newOrder.leaveAtTheDoor,
        CARD_ID: newOrder.paymentCard?.id,
        PAY_SYSTEM_ID: newOrder.payment?.id,
      },
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      },
    )

    return {
      result: CreatedOrder(data.result),
    }
  },

  async getOrderData({ cancel = false }: { cancel?: boolean } = {}) {
    const { $axios } = useNuxtApp()

    const abortController = cancel ? getAbortController('constructor_catalog') : null
    const { data } = await $axios.get('/api/v1/darkstore/order', {
      signal: abortController?.signal,
    })

    return OrderCheckoutSerializer(data.result)
  },
  remove({ id, reason }: { id: string; reason?: string }) {
    const { $axios } = useNuxtApp()

    return $axios.delete(`/api/v1/order/${id}/cancel?reason=${reason || ''}`)
  },
  addPromocode({ code }: { code: string }) {
    const { $axios } = useNuxtApp()

    return $axios.post(`/api/v1/darkstore/order/coupon/${code}`)
  },
  removePromocode({ code }: { code: string }) {
    const { $axios } = useNuxtApp()

    return $axios.delete(`/api/v1/darkstore/order/coupon/${code}`)
  },
  evaluation(data: Evaluation) {
    const { $axios } = useNuxtApp()

    return $axios.post('/api/v1/darkstore/order/evaluation', getFormData({
      order_id: data.orderId,
      evaluation: data.rate,
      evaluation_comments: data.rateComments,
      user_comment: data.userComment,
      photo: data.photos,
    }), {
      headers: {
        post: undefined,
      },
    })
  },
  async getReviewAnswersGroupByRating() {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.get<{ result: Array<{
      EVALUATION: number
      COMMENTS: string[]
    }> }>('/api/v1/darkstore/order/evaluation')

    return data.result.map((el) => {
      return {
        rating: el.EVALUATION,
        answers: el.COMMENTS,
      }
    })
  },

  async updateOrderStatus(id: string) {
    const { $axios } = useNuxtApp()

    const { data } = await $axios.patch(`/api/v1/user/orders/${id}/payments`)

    return data.result
  },
}
