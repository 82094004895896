import type { User } from '~/type/User'

export default function (el: { [ el: string ]: any } = {}): User {
  return {
    id: +el.ID,
    avatar: el.PERSONAL_PHOTO,
    name: el.NAME,
    lastName: el.LAST_NAME,
    birthday: el.PERSONAL_BIRTHDAY,
    gender: el.PERSONAL_GENDER,
    login: el.LOGIN,
    email: el.EMAIL,
    phone: el.PERSONAL_PHONE,
    cart: el.BASKET_AMOUNT || 0,
    confirm: {
      email: el.UF_EMAIL_CONFIRMED || false,
      phone: el.UF_PHONE_CONFIRMED || false,
    },
    socials: {
      instagram: el.UF_INSTAGRAM_LINK,
      telegram: el.UF_TELEGRAM_LINK,
      facebook: el.UF_FACEBOOK_LINK,
    },
  }
}
