/**
 * У нас есть логика скрытия заказов с определёнными статусами, которые пользователь закрыл кликнув
 * На крестик в модалке этого заказа
 * Мы приводим эти статусы к понятному нам виду (cancelled, completed, not-paid)
 * С АПИ они приходят (на данный момент по крайней мере) как:
 * Заказ отменен, заказ не оплачен, заказ доставлен (возможны различия, но смысл статусов именно такой)
 * Список может дополняться и изменяться
 * В итоге: если статус заказа, переданный в эту функцию будет равен тем, что перечислены здесь, то мы
 * Говорим, что этот заказ можно закрыть (понятно из названия фунции)
 */
export function isAllowCloseOrder(status: string) {
  if (status && typeof status === 'string') {
    switch (status) {
      case 'cancelled':
      case 'completed':
      case 'not-paid':
        return true
      default:
        return false
    }
  }
  else {
    console.error('Вы не передали статус заказа, либо передали его не верно, проверьте, что вы передаёте строку')
  }
}

/**
 * Мы не выводим на главную заказы со статусом, который указан ниже (not-paid-yet)
 * Возможно, в будущем это изменится, туда добавятся новые статусы, пока так
 */
export function isNeedShowOrder(status: string) {
  if (status && typeof status === 'string') {
    switch (status) {
      case 'not-paid-yet':
        return false
      default:
        return true
    }
  }
  else {
    console.error('Вы не передали статус заказа, либо передали его не верно, проверьте, что вы передаёте строку')
  }
}
