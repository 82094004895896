import { stringify } from 'qs'
import ProductSerializer from '~/serializer/product/Product'
import SortSerializer from '~/serializer/Sort'
import PaginationSerializer from '~/serializer/Pagination'
import CategorySerializer from '~/serializer/CategoryBase'
import { getAbortController } from '~/helper/getAbortController'

interface Params {
  page?: number
  size: number
  section?: number
  sort?: string
  query: string
  cancel?: boolean
}

export default {
  async byQuery(
    { page, size, section, sort, query, cancel }: Params,
  ) {
    const { $axios } = useNuxtApp()
    const abortController = cancel ? getAbortController('search-by-query') : null

    const result = await $axios.get<
      { result: { ITEMS: any[]; SECTIONS_BLOCK: { ITEMS: any[] }; SORT_TYPES: any[]; PAGINATION: any } }
    >(`/api/v2/search?${stringify({
        page,
        size,
        section,
        sort,
        q: query,
      })}`, {
      signal: abortController?.signal,
    })

    return {
      list: result.data.result.ITEMS?.length ? result.data.result.ITEMS.map((item: any) => ProductSerializer(item)) : [],
      category: result.data.result.SECTIONS_BLOCK?.ITEMS?.length ? result?.data.result.SECTIONS_BLOCK?.ITEMS?.map((el: any) => CategorySerializer(el)) : [],
      sort: result?.data.result?.SORT_TYPES?.map((el: any) => SortSerializer(el)) || [],
      pagination: PaginationSerializer(result.data?.result?.PAGINATION || null),
    }
  },
}
