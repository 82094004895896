import { stringify } from 'qs'
import ProductSerializer from '~/serializer/product/Product'
import SortSerializer from '~/serializer/Sort'
import PaginationSerializer from '~/serializer/Pagination'
import type { Product } from '~/type/product/Product'
import type Sort from '~/type/Sort'
import type { APIRequestSuccess } from '~/type/APIRequestStatus'
import APIRequestStatus from '~/serializer/APIRequestStatus'

export default {
  async get({ page, size, sort, products }: { page?: number; size?: number; sort?: string; products?: string }) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.get<APIRequestSuccess<{ ITEMS: any[]; SORT_TYPES: any[] } >>(`/api/v2/favorite?${stringify({ page, size, sort, products })}`)
    const list: Product[] = data.result.ITEMS?.length ? data.result.ITEMS.map((item: { [key: string]: any }) => ProductSerializer(item)) : []
    const sortList: Sort[] = data.result?.SORT_TYPES?.map((el: { [key: string]: any }) => SortSerializer(el)) || []
    return {
      list,
      sort: sortList,
      pagination: PaginationSerializer(data.result?.PAGINATION || {}),
      title: 'Избранное',
      description: 'Появятся тут, если нажать на сердечко в карточке товара',
    }
  },
  async add(id: number) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.put(`/api/v1/favorite/${id}?show_entity=true`, null, {
      'axios-retry': {
        retries: 2,
      },
    })
    return ProductSerializer(data.result)
  },
  async remove(id: number) {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.delete(`/api/v1/favorite/${id}?show_entity=true`, {
      'axios-retry': {
        retries: 2,
      },
    })
    return ProductSerializer(data.result)
  },

  async removeAll() {
    const { $axios } = useNuxtApp()

    const { data } = await $axios.delete('/api/v1/favorite/all')

    return APIRequestStatus(data)
  },
}
