/* eslint-disable @typescript-eslint/indent */

import { useTokenStore } from '~/store/token'
import { useUserStore } from '~/store/user'
import { useCartStore } from '~/store/cart'
import { useFavoriteStore } from '~/store/favorite'

export default async function ({ response }:
  {
    response: { [key: string]: any }
  }) {
  const config = useRuntimeConfig()
  const { $api, $error } = useNuxtApp()

  const { set: setToken } = useTokenStore()
  const { set: setData } = useUserStore()
  const { setCart } = useCartStore()
  const { setList: setFavoriteList } = useFavoriteStore()

  setToken(response.token)
  setData(response.user)
  setFavoriteList({ list: response.favorite })

  const cookieAccessToken = useCookie('user-access-token', {
    domain: config.crossAuthDomain,
    maxAge: 60 * 60 * 24 * 30,
  })

  cookieAccessToken.value = response.token.accessToken.value

    /**
    При авторизации запрашиваем корзину пользователя и сихронизируем её относительно бэка
    */
    try {
      const data = await $api.order.getOrderData()
      setCart(data)
    }

    catch (e) {
      new $error.simple({
        name: 'Ошибка',
        description: 'Ошибка при получении корзины, попробуйте позже',
        message: 'Ошибка при получении корзины',
      })
    }
}
