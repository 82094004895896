import { stringify } from 'qs'
import ProductSerializer from '~/serializer/product/Product'
import PaginationSerializer from '~/serializer/Pagination'
import { getAbortController } from '~/helper/getAbortController'
import convertCategories from '~/helper/convertCategories'

export default {
  async getSelectionByID({ id, page, sort, size = 20, cancel = false }: { id: number; page?: number; sort?: string; size?: number; cancel?: boolean }) {
    const { $axios } = useNuxtApp()
    const abortController = cancel ? getAbortController('constructor_catalog') : null
    const { data } = await $axios.get<{
      result: {
        ID: number
        PRODUCTS: { ITEMS: any[]; PAGINATION: any }
        NAME?: string
        SECTIONS: { [key: string]: any; CHILDREN?: any[] }
      }
    }>(`/api/v1/darkstore/products/selection?${stringify({
      selectionId: id,
      sort,
      page,
      size,
    })}`, {
      cache: {
        ttl: 5 * 60 * 1000,
      },
      signal: abortController?.signal,
    })

    return {
      id: data.result?.ID,
      title: data.result?.NAME ?? '',
      list: data.result.PRODUCTS?.ITEMS?.length ? data.result.PRODUCTS.ITEMS.map((item: any) => ProductSerializer(item)) : [],
      pagination: PaginationSerializer(data.result.PRODUCTS?.PAGINATION || null),
      tagList: data?.result.SECTIONS?.CHILDREN?.length ? convertCategories(data?.result.SECTIONS?.CHILDREN) : [],
    }
  },
}
