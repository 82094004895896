import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9MY4eAfYMX from "/app/node_modules/@artmizu/yandex-metrika-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_piniaPersisted_chqsenZ4gt from "/app/plugins/0.piniaPersisted.ts";
import _0_sentry_client_qe3yzkXffc from "/app/plugins/0.sentry.client.ts";
import _1_errorHandler_IxOgYWQuWH from "/app/plugins/1.errorHandler.ts";
import _1_pinia_client_EmONChjehf from "/app/plugins/1.pinia.client.ts";
import index_KOSqxDeJTj from "/app/plugins/2.api/index.ts";
import _5_checkCartChanges_client_B97gZHepOc from "/app/plugins/5.checkCartChanges.client.ts";
import _5_emitter_vreY9x1TiI from "/app/plugins/5.emitter.ts";
import cypressSetup_client_rKcaHk2zzA from "/app/plugins/cypressSetup.client.ts";
import mask_client_ZP7BE6rJzn from "/app/plugins/mask.client.ts";
import mindbox_client_01iWBm4suY from "/app/plugins/mindbox.client.ts";
import refiner_client_B1E9XFG9py from "/app/plugins/refiner.client.ts";
import resize_client_MHScPV7hjy from "/app/plugins/resize.client.ts";
import index_Q3HO0ypKip from "/app/plugins/validation/index.ts";
import vuePlugins_NKyCNQ9bPz from "/app/plugins/vuePlugins.ts";
import index_client_5SnmYRqifO from "/app/plugins/dataLayer/index.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_9MY4eAfYMX,
  chunk_reload_client_UciE0i6zes,
  _0_piniaPersisted_chqsenZ4gt,
  _0_sentry_client_qe3yzkXffc,
  _1_errorHandler_IxOgYWQuWH,
  _1_pinia_client_EmONChjehf,
  index_KOSqxDeJTj,
  _5_checkCartChanges_client_B97gZHepOc,
  _5_emitter_vreY9x1TiI,
  cypressSetup_client_rKcaHk2zzA,
  mask_client_ZP7BE6rJzn,
  mindbox_client_01iWBm4suY,
  refiner_client_B1E9XFG9py,
  resize_client_MHScPV7hjy,
  index_Q3HO0ypKip,
  vuePlugins_NKyCNQ9bPz,
  index_client_5SnmYRqifO
]