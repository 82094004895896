import { defineStore } from 'pinia'

export const useSocialsStore = defineStore('socials', () => {
  return {
    phone: '8 (800) 511-05-05',
    telegram: 'https://t.me/online05ru_bot',
    whatsApp: 'https://wa.me/78722515151',
    appStoreUrl: 'https://apps.apple.com/ru/app/05-ru/id1539023015',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.x05ru.release&hl=ru',
    email: 'info@05.ru',
    corpEmail: 'corp@05.ru',
  }
})
