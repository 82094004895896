<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { UI05Button, UI05Modal, UI05RowSlider, UI05Textarea, UI05Tooltip } from 'ui-05'
import { useOrderReviewModal } from '~/store/modal/orderReview'
import { useActionStatusStore } from '~/store/modal/actionStatus'
import empty from '~/components/ImgLazy/asset/empty.png'
import type Evaluation from '~/type/order/Evaluation'

const { $api, $error } = useNuxtApp()

const { productList, id } = storeToRefs(useOrderReviewModal())
const actionStore = useActionStatusStore()
const { disable } = useOrderReviewModal()
const review = ref<Evaluation>({
  orderId: '',
  rate: 0,
  userComment: '',
  rateComments: [],
  photos: [],
})
const isRated = ref(false)

const answers = await useDataEntity('order-review-answers', $api.order.getReviewAnswersGroupByRating, {
  params: {},
  onError: () => {
    UI05Tooltip({ title: 'Произошла ошибка при получении вопросов' })
  },
})

const sendReview = useActionEntity($api.order.evaluation, {
  params: review,
  onSuccess: () => {
    close()
    actionStore.enable({ desc: 'Ваша оценка принята', title: 'Спасибо!', status: 'success' })
    actionStore.onReject = () => {
      actionStore.active = false
      location.reload()
      actionStore.disable()
    }
  },
  onError: ({ serialized }) => {
    close()
    actionStore.enable({ title: 'Ошибка', desc: 'Попробуйте позже оценить заказ в личном кабинете', status: 'error' })
    if (serialized)
      new $error.base({ ...serialized, loggerTitle: 'Произошла ошибка при отправке данных отзыва' })
  },
})

const questions = computed(() => {
  const group = answers.data.value?.find(a => a.rating === review.value.rate)
  return group ? group.answers : []
})

const rateText = computed(() => {
  if (review.value.rate <= 3)
    return 'Что не понравилось?'
  else if (review.value.rate > 3)
    return 'Спасибо, что вам понравилось!'
})

function onCheckboxChange(e: Event) {
  const target = e.target as HTMLInputElement
  const index = review.value.rateComments.indexOf(target.value)
  if (!target.checked && index !== -1)
    review.value.rateComments.splice(index, 1)
  else
    review.value.rateComments.push(target.value)
}

function close() {
  review.value = {
    orderId: '',
    rate: 0,
    userComment: '',
    rateComments: [],
    photos: [],
  }
  isRated.value = false
  disable()
}
</script>

<template>
  <UI05Modal
    show
    title="Оцените заказ"
    max-width="500px"
    overlay-theme="light"
    @close="close"
  >
    <template #default>
      <hr class="order-review__line">
      <div class="order-review">
        <p class="order-review__title">
          Заказ № {{ id }}
        </p>
        <UI05RowSlider
          v-if="productList"
          v-slot="{ el }"
          :list="productList"
          :offset-between-elements="6"
        >
          <div
            :key="el.id"
            class="order-review__img-wrap"
            :class="[{ 'order-review__img-wrap_not-available': el.isUnavailable }]"
          >
            <img
              class="order-review__img"
              :src="el.thumbnail.x1 ? el.thumbnail.x1 : empty"
              :srcset="`${el.thumbnail.x2 ? el.thumbnail.x2 : empty} 2x`"
              :alt="el.name"
            >
          </div>
        </UI05RowSlider>
      </div>
      <div class="order-rating">
        <p class="order-rating__title">
          Как вам заказ?
        </p>
        <ProductRating
          class="mb-20"
          size="45"
          interactive
          @update:model-value="review.rate = $event, isRated = true"
        />
        <template v-if="isRated">
          <div class="order-rating__questions-block">
            <p class="order-rating__question-block-title">
              {{ rateText }}
            </p>
            <label
              v-for="question in questions"
              :key="question"
              class="order-rating__question"
            >
              <span class="order-rating__question-title">{{ question }}</span>
              <input
                class="order-rating__question-input"
                type="checkbox"
                :name="question"
                :value="question"
                @change="onCheckboxChange"
              >
            </label>
          </div>
          <div class="order-rating__comment">
            <p class="order-rating__comment-title">
              Комментарий
            </p>
            <UI05Textarea :height="80" @update:model-value="review.userComment = $event" />
          </div>
          <div class="order-rating__send-files">
            <p class="order-rating__send-files-title">
              Прикрепите не более 3-4 фото:
            </p>
            <PhotoUpload multiple @update="review.photos = $event" />
          </div>
          <UI05Button
            class="order-rating__send-btn"
            :loading="sendReview.loading.value"
            size="49"
            @click.stop="review.orderId = id, sendReview.request(review)"
          >
            Отправить
          </UI05Button>
        </template>
      </div>
    </template>
  </UI05Modal>
</template>

<style scoped lang="postcss">
.order-review {
  padding: 15px;
  margin: 15px 0 30px;
  background: #F8F8F8;
  border-radius: 12px;

  &__line {
    color: #F2F2F2;
    opacity: 0.3;
  }

  &__title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 18px;
  }

  &__img-block {
    display: flex;
  }

  &__img-wrap {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 5px 10px;
    background-color: white;
    border-radius: 5px;

    &_not-available {
      opacity: 0.4;
    }
  }

  &__img {
    max-height: 50px;
    mix-blend-mode: multiply;
  }
}

.order-rating {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__send-btn {
    width: 100%;
  }

  &__send-files {
    width: 100%;
    margin-bottom: 30px;
  }

  &__send-files-title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
    color: #1A1A1A;
  }

  &__comment {
    width: 100%;
    margin-bottom: 25px;
  }

  &__comment-title {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #1A1A1A;
  }

  &__question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #F3F3F3;
  }

  &__question-block-title {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }

  &__question-title {
    font-size: 16px;
    line-height: 16px;
  }

  &__question-input {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: #F6F6F6;
    border-radius: 100%;

    &:checked {
      background-image: url("./asset/check.svg");
      background-position: 50% 50%;
      background-size: 100%;
    }
  }

  &__questions-block {
    width: 100%;
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }
}
</style>
