<script setup lang="ts">
import { UI05Button } from 'ui-05'
import { declination, paginationArray } from '@artmizu/utils'
import { stringify } from 'qs'

interface Props {
  quantity: number
  quantityOnPage: number
  current?: number
  count?: number
  entitiesList?: [string, string, string]
}

const props = withDefaults(defineProps<Props>(), {
  current: 1,
  count: 1,
  entitiesList: () => ['товар', 'товара', 'товаров'],
})
const emit = defineEmits<{ (e: 'change-page' | 'load-more', val: number): void }>()

const route = useRoute()

const path = ref<ReturnType<typeof paginationArray>>([])
const mobilePath = ref<ReturnType<typeof paginationArray>>([])
const totalQuantity = computed(() => {
  /**
  * На предпоследней странице мы должны вывести остаток того, сколько еще нужно подгрузить сущностей
  * зачастую там не столько, сколько может быть на странице, а меньше. Например если на странице 100 сущностей,
  * а на каждой странице мы выводим по 30, то на последней странице нужно подгрузить 10, тут это и считаем
  */
  if (props.current === props.count - 1 && props.quantity % props.quantityOnPage !== 0)
    return props.quantity % props.quantityOnPage
  else
    return props.quantityOnPage
})
const declinationValue = computed(() => {
  return declination(totalQuantity.value, props.entitiesList)
})
const link = computed(() => {
  const query = { ...route.query }
  if (query.page)
    delete query.page
  const queryLength = Object.keys(query)?.length || 0
  return queryLength
    ? `${route.path}?${stringify(query)}&`
    : `${route.path}?`
})

watch(() => props.current, () => {
  setPath()
})
watch(() => props.count, () => {
  setPath()
})

setPath()

function setPath() {
  path.value = paginationArray({
    current: props.current,
    count: props.count,
  })
  mobilePath.value = paginationArray({
    current: props.current,
    count: props.count,
    visiblePageCount: 5,
  })
}

function changePage(num: number) {
  if (num !== props.current)
    emit('change-page', num)
}

function loadMore(num: number) {
  emit('load-more', num)
}
</script>

<template>
  <div class="page-nav">
    <div class="page-nav__col">
      <UI05Button
        v-if="current + 1 <= count"
        size="49"
        class="page-nav__load-more"
        data-e2e="page-nav-load-more"
        @click="loadMore(current + 1)"
      >
        Еще {{ totalQuantity }} {{ declinationValue }}
      </UI05Button>
      <UI05Button
        v-else
        disabled
        size="49"
        class="page-nav__load-more"
        :class="[{ 'page-nav__load-more_loaded': current === count }]"
      >
        Всё загружено
      </UI05Button>
    </div>
    <div class="page-nav__col page-nav__col_pagination">
      <div class="page-nav__nav">
        <div
          class="page-nav__arrow page-nav__arrow_left"
          :class="{ 'page-nav__arrow_disabled': current === 1 }"
          @click="current !== 1 && changePage(current - 1)"
        >
          <nuxt-link
            :to="current - 1 === 1 ? (link ? link : '#') : `${link}page=${current - 1}`"
            :tag="link && current !== 1 ? 'a' : 'div'"
            event=""
            class="link-icon link-icon_wu page-nav__arrow-wrap"
          >
            <div class="link-icon__icon-left-arrow" />
          </nuxt-link>
        </div>
        <div class="page-nav__nums page-nav__nums_desktop">
          <nuxt-link
            v-for="(page, index) in path"
            :key="index"
            :tag="!link || page === '...' ? 'div' : 'a'"
            :to="index === 0 ? (link ? link : '#') : `${link}page=${page}`"
            event=""
            class="page-nav__num"
            data-e2e="page-nav-num"
            :class="{ 'page-nav__num_active': page === current, 'page-nav__num_passive': page === '...' }"
            @click="typeof page !== 'string' && changePage(page)"
          >
            {{ page }}
          </nuxt-link>
        </div>
        <div class="page-nav__nums page-nav__nums_mobile">
          <nuxt-link
            v-for="(page, index) in mobilePath"
            :key="index"
            :tag="!link || page === '...' ? 'div' : 'a'"
            :to="index === 0 ? (link ? link : '#') : `${link}page=${page}`"
            event=""
            class="page-nav__num"
            data-e2e="page-nav-num"
            :class="{ 'page-nav__num_active': page === current, 'page-nav__num_passive': page === '...' }"
            @click="typeof page !== 'string' && changePage(page)"
          >
            {{ page }}
          </nuxt-link>
        </div>
        <div
          class="page-nav__arrow page-nav__arrow_right"
          :class="{ 'page-nav__arrow_disabled': current === count }"
          @click="current !== count && changePage(current + 1)"
        >
          <nuxt-link
            :tag="link && current !== count ? 'a' : 'div'"
            :to="`${link}page=${current + 1}`"
            event=""
            class="link-icon link-icon_wu page-nav__arrow-wrap"
          >
            <div class="link-icon__icon-right-arrow" />
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.page-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__col {
    display: flex;
    justify-content: center;

    &:first-child {
      margin-bottom: 25px;
    }
  }

  &__load-more {
    padding: 13px 162px;
    border: 1px solid rgba(0, 0, 0, 0.13);
  }

  &__nums {
    display: flex;
    align-items: center;
    padding: 5px 10px 6px;

    &_mobile {
      display: none;
      gap: 5px;
      padding: 0;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    line-height: 11px;
    cursor: pointer;
    border-radius: 4px;

    &_disabled ^&__arrow-wrap {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    &_left {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .link-icon__icon-left-arrow {
        top: 0px;
        background-size: 8px;
      }

      .link-icon__text {
        margin-left: 4px;
      }
    }

    &_right {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .link-icon__icon-right-arrow {
        top: 0px;
        background-size: 8px;
      }

      .link-icon__text {
        margin-right: 4px;
      }
    }
  }

  &__arrow-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__num {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    min-height: 35px;
    margin-right: 2px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 3px;

    &:last-child {
      margin-right: 0;
    }

    &:hover:not(&_passive) {
      color: #e30613;
    }

    &_active {
      color: #e30613;
      border-color: #e30613;
    }

    &_passive {
      cursor: auto;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;

    &__load-more {
      width: 100%;
      padding: 7px;
      border-color: transparent;

      &_loaded {
        width: 100%;
      }

      .link-icon__icon-bottom-arrow {
        top: 1px;
      }
    }

    &__nums {
      display: none;

      &_mobile {
        display: flex;
      }
    }

    &__col:first-child {
      width: 100%;
      margin-bottom: 20px !important;
    }

    &__col_pagination {
      width: 100%;
    }

    &__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: 0;
    }

    &__arrow {
      display: flex;
      width: 35px;
      height: 35px;
      line-height: 12px;
    }

    &__arrow-wrap {
      padding-top: 2px;
    }

    .link-icon__text {
      display: none;
    }

    .link-icon__icon-right-arrow,
    .link-icon__icon-left-arrow {
      background-size: 8px;
    }

    &__num {
      margin-right: 0;
      font-size: 19px;
      line-height: 23px;
    }
  }
}
</style>
