import { formatAddress } from '@artmizu/utils'
import { ru } from 'date-fns/locale'
import { parse } from 'date-fns'
import OrderProduct from './OrderProduct'
import formatDate from '~/helper/formatDate'
import type { Order, StatusType } from '~/type/order/Order'

export default function (el: {
  [key: string]: any
  PRODUCTS: any[]
}): Order {
  function getStatusType(el: any): StatusType {
    switch (el.STATUS_ID.ID) {
      case 'M':
      case 'PP':
        return 'not-paid'
      case 'CU':
      case 'Q':
      case 'RJ':
        return 'cancelled'
      case 'W':
        return 'accepted'
      case 'B':
      case 'CM':
      case 'RS':
        return 'collect'
      case 'DD':
      case 'G':
        return 'transit'
      case 'F':
      case 'E':
        return 'completed'
      default:
        return 'not-paid-yet'
    }
  }

  // Получаем минуты из строки даты доставки
  function getDeliveryMinutes(time: string) {
    const date = parse(time, 'dd.MM.yyyy HH:mm:ss', new Date(), { locale: ru })

    return Math.ceil(((+date - +new Date()) / 1000) / 60)
  }

  return {
    id: el.ID,
    date: formatDate(el.DATE_INSERT, 'd MMMM'),
    number: el.ACCOUNT_NUMBER,
    price: {
      common: {
        value: el.PRICE,
      },
    },
    status: {
      name: el.STATUS_ID?.NAME === 'Ожидает оплаты' ? 'Отменен' : el.STATUS_ID?.NAME,
      description: el?.STATUS_DESCRIPTION,
      color: getStatusType(el) === 'not-paid' ? 'rgb(129, 129, 129)' : el.STATUS_ID?.COLOR,
      type: getStatusType(el) === 'not-paid' ? 'cancelled' : getStatusType(el),
    },
    delivery: {
      name: el.DELIVERY_NAME,
      date: el.DELIVERY_DATE ? formatDate(el.DELIVERY_DATE, 'd MMMM') : undefined,
      time: el.DARKSTORE_DELIVERY_ESTIMATE && getDeliveryMinutes(el.DARKSTORE_DELIVERY_ESTIMATE),
      detail: el?.DECODED_ADDRESS
        ? {
            address: formatAddress({ city: el?.DECODED_ADDRESS.CITY, street: el?.DECODED_ADDRESS.STREET, house: el?.DECODED_ADDRESS.HOUSE }),
            city: el?.DECODED_ADDRESS.CITY,
          }
        : undefined,
    },
    payResult: el.PAY_RESULT,
    isEvaluated: el.ORDER_EVALUATED,
    checkLink: el?.DARKSTORE_PAY_CHECK_LINK,
    isDelay: el.DARKSTORE_DELIVERY_DELAY === 'DELAY',
    courierPhone: el?.DARKSTORE_COURIER_PHONE,
    supportLink: el.DARKSTORE_SUPPORT_LINK,
    pay: el.PAY_SYSTEM_NAME,
    leaveAtTheDoor: el?.DARKSTORE_LEAVE_AT_THE_DOOR,
    link: el.PAY_SYSTEM_LINK,
    products: el.PRODUCTS?.map((el: any) => OrderProduct(el)) || [],
    isChanged: el.DARKSTORE_HAS_CHANGES,
    changedPrice: el.PREVIOUS_PRICE || 0,
    paymentCardID: el.PAY_CARD_ID,
  }
}
