import type Thumbnail from '~/type/Thumbnail'

export default function (el: { [key: string]: any } = {}): Thumbnail {
  return {
    x1: el['1X'],
    x2: el['2X'],
    x3: el['3X'],
    x4: el['4X'],
  }
}
