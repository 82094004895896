<script lang="ts" setup>
import type { Category } from '~/type/Category'

const props = defineProps<{
  list: Category[]
  currentCategory?: Category
}>()
const emit = defineEmits<{
  (e: 'click', category?: Category): void
}>()
const route = useRoute()

const isMoreActive = ref(false)
const currentList = computed(() => isMoreActive.value ? props.list : props.list.slice(0, 7))
</script>

<template>
  <div class="category-list" @click.self="emit('click')">
    <button
      v-for="item in currentList"
      :key="item.id"
      class="category-list__link"
      :class="{ 'category-list__link_active': currentCategory?.id === item.id || Number(route.query?.section) === Number(item.id) }"
      @click="emit('click', item)"
    >
      <span class="category-list__link-name">{{ item.name }}</span>
      <span class="category-list__link-count">{{ item.productsCount }}</span>
    </button>
    <div
      v-if="list.length > 7 && !isMoreActive"
      class="category-list__link"
      @click="isMoreActive = true"
    >
      <span>Ещё</span>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.category-list {
  width: 100%;

  &__link {
    display: inline-block;
    padding: 12px 14px;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    background-color: white;
    border: 1px solid #f3f3f3;
    border-radius: 100px;

    &-count {
      margin-left: 5px;
      color: darkgray;
    }

    &_active {

      & > span {
        color: #E30613;
      }
      border: 1px solid #E30613;
    }

    &:hover {

      & > span {
        color: #E30613;
      }
      border: 1px solid #E30613;
    }
  }

  @media (max-width: 800px) {

    &__link {
      padding: 10px;
      margin-right: 6px;
    }
  }
}
</style>
