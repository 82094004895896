import { stringify } from 'qs'
import { useOrderStore } from '~/store/order'
import { getAbortController } from '~~/helper/getAbortController'
import { isNeedShowOrder } from '~/helper/orderShowFilters'
import CatalogPageSerializer from '~/serializer/page/CatalogPage'
import CategorySerializer from '~/serializer/Category'
import QuestionSerializer from '~/serializer/Question'
import OrderSerializer from '~/serializer/Order'
import ScheduleSerializer from '~/serializer/Schedule'
import StoryBannerSerializer from '~/serializer/StoryBanner'
import SelectionSerializer from '~/serializer/Selection'
import { BaseRecipeSerializer } from '~/serializer/recipe/Recipe'
import type { Order } from '~/type/order/Order'

export default {
  async main() {
    const { $axios } = useNuxtApp()

    // Логика фильтрации товаров, нужна для главной страницы, чтобы не писать её там, перенесли сюда, где ей и место
    const orderStore = useOrderStore()
    function filterActiveOrders(orders: Order[]) {
      return orders.filter((el) => {
        // Если заказ подходит под все наши условия (нужный статус, нет в списке "закрытых" заказов) то выводим его
        if (isNeedShowOrder(el.status.type) && !orderStore.disableOrdersList.includes(el?.id)) {
          return true
        }
        else if (!isNeedShowOrder(el.status.type) && orderStore.disableOrdersList.includes(el.id)) {
          /**
           * Если  заказ не выводится на главной, но его айди есть в списке "закрытых" заказов, то удаляем его оттуда,
           * чтобы не захламлять этот список, т.к. он хранится в куках. Такой заказ на страницу мы НЕ выводим
           */
          orderStore.removeFromDisableOrdersList(el.id)
          return false
        }

        return false
      }) || []
    }

    const { data } = await $axios.get<{
      result: {
        CATEGORIES_LIST: Array<{
          CHILDREN: any[]
        }>
        QUESTION_ANSWERS: any[]
        USER_ORDERS: any[]
        PRODUCTS_SELECTIONS: any[]
        ONBOARDING: any[]
        RECIPES: any[]
      }
    }>('/api/v1/darkstore/snippets/main')

    return {
      category: data.result?.CATEGORIES_LIST?.length
        ? data.result.CATEGORIES_LIST[0].CHILDREN.map((el: any) => CategorySerializer(el))
        : [],
      question: data.result?.QUESTION_ANSWERS?.length
        ? data.result.QUESTION_ANSWERS.map((el: any) => QuestionSerializer(el))
        : [],
      order: data.result?.USER_ORDERS?.length
        ? filterActiveOrders(data.result.USER_ORDERS.map((el: any) => OrderSerializer(el)))
        : [],
      selection: data.result?.PRODUCTS_SELECTIONS?.length
        ? data.result.PRODUCTS_SELECTIONS.slice(-10).map((el: any) => SelectionSerializer(el))
        : [],
      storyBanner: data.result?.ONBOARDING?.length >= 3 ? data.result?.ONBOARDING.slice(-3).map((el: any) => StoryBannerSerializer(el)) : [],
      recipes: (Array.isArray(data.result?.RECIPES) && data.result?.RECIPES.length) ? data.result?.RECIPES?.map((el: any) => BaseRecipeSerializer(el)) : [],
    }
  },
  async getSchedule() {
    const { $axios } = useNuxtApp()
    const { data } = await $axios.get(`/api/v1/darkstore/snippets/main?${stringify({ include_block: 'SCHEDULE' })}`)
    return data.result?.SCHEDULE ? ScheduleSerializer(data.result?.SCHEDULE) : null
  },
  async catalog(
    { seo = '', id, page, sort, size = 60, cancel = false, mode, discountOnly, path }: {
      seo?: string
      path?: string
      q?: string
      page?: number
      id?: number
      sort?: string
      cancel?: boolean
      size?: number
      mode?: 'all_products'
      discountOnly?: boolean
    },
  ) {
    const { $axios } = useNuxtApp()
    const abortController = cancel ? getAbortController('constructor_catalog') : null
    /**
     * SEO берется из браузерной строки, поэтому там УРЛ уже может быть закодирован,
     * например символ % приобразован в %25. Если такое закодировать еще раз, получится
     * фигня и двойное кодирование. Поэтому раскодируем УРЛ и потом спокойно кодируем еще раз
     * перед отправкой на бэк
     */
    const decodedURL = decodeURI(seo)

    const { data } = (
      await $axios.get(
        `/api/v1/snippets/vue/catalog?${stringify({
          seo_link: `${decodedURL}${stringify({
            section_id: id,
          })}`,
          sort,
          size,
          page,
          discount_only: discountOnly,
          mode,
        })}`,
        {
          cache: {
            ttl: 5 * 60 * 1000,
          },
          signal: abortController?.signal,
        },
      )
    )

    return CatalogPageSerializer({ ...data.result, path, sale: discountOnly })
  },
}
