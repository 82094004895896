<script setup lang="ts">
import { UI05Button } from 'ui-05'

interface Props {
  price: number | undefined
  active?: boolean
  loading?: boolean
  quantity?: number
  max?: number
  icon?: string
}

withDefaults(defineProps<Props>(), {
  active: false,
  loading: false,
  quantity: 1,
  max: 0,
})

const emit = defineEmits<{ (e: 'decrease' | 'increase' | 'add'): void }>()
</script>

<template>
  <UI05Button
    v-if="active"
    fluid
    theme="white"
    size="49"
    class="cart-button cart-button_counter"
  >
    <div
      class="cart-button__remove"
      @click="emit('decrease')"
    />
    <div class="cart-button__text">
      <div
        v-show="loading"
        class="icon-loading-oval"
      />
      <span v-show="!loading"> {{ quantity }} х {{ price }} ₽</span>
    </div>
    <div
      class="cart-button__add"
      :class="[{ 'cart-button__add_disabled': quantity >= max }]"
      @click="emit('increase')"
    />
  </UI05Button>
  <UI05Button
    v-else
    size="49"
    fluid
    class="cart-button"
    :loading="loading"
    icon="cart"
    @click.self="emit('add')"
  >
    <span class="cart-button__title">
      Добавить в корзину
    </span>
    <span class="cart-button__title cart-button__title_mobile">
      В корзину {{ price }}₽
    </span>
  </UI05Button>
</template>

<style lang="postcss" scoped>
.cart-button {

  &__remove {
    width: 16px;
    height: 16px;
    background-image: svg-load('./asset/minus.svg', fill=#000);
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      background-image: svg-load('./asset/minus.svg', fill=#ee272d);
      transition-duration: 0.2s;
    }
  }

  &__title_mobile{
    display: none;
  }

  &__add {
    width: 16px;
    height: 16px;
    background-image: svg-load('./asset/plus.svg', fill=#000);
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      background-image: svg-load('./asset/plus.svg', fill=#ee272d);
      transition-duration: 0.2s;
    }

    &_disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  &__text {
    padding: 0 15px;
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 23px;

    .cart-button__title{
      display: none;

      &_mobile{
        display: block;
      }
    }

  }
}
</style>

<style lang="postcss">
.cart-button_counter {
  justify-content: space-between;
  padding-right: 21px;
  padding-left: 21px;

  &:hover {
    background-color: #fff !important;
  }
}

@media (max-width: 800px) {

  .cart-button{

    .icon-cart{
      display: none;
    }
  }}
</style>
