import { AxiosError } from 'axios'
import type { Product } from '~~/type/product/Product'
import { ErrorSerializer } from '~~/serializer/Error'
import { useFavoriteStore } from '~/store/favorite'

type Status = 'loading' | 'error' | 'initial' | 'success'

export function useActionFavorite(id: number, price: number, {
  errorType = 'simple',
  onLoading = () => {},
  onSuccess = () => {},
  onError = () => {},
}: {
  errorType?: 'simple' | 'base'
  onLoading?: () => void
  onSuccess?: ({ data }: { data: Product }) => void
  onError?: (e: unknown) => void
} = {}) {
  const { $error, $api, $emitter } = useNuxtApp()
  const favoriteStore = useFavoriteStore()
  const success = ref(false)
  const error = ref(false)
  const loading = ref(false)
  const initial = ref(true)
  const status = ref<Status>('initial')
  const active = computed(() => favoriteStore.isExist(id))
  const product = computed(() => favoriteStore.getProduct(id))

  async function request(type: 'add' | 'remove') {
    changeStatus('loading')
    onLoading()

    try {
      const product = await $api.favorite[type](id)
      favoriteStore[type]({ id })
      changeStatus('success')
      onSuccess({ data: product })

      return product
    }
    catch (error) {
      changeStatus('error')
      onError(error)

      if (error instanceof AxiosError)
        new $error[errorType](ErrorSerializer(error))
      else
        throw error
    }
  }

  async function add() {
    await request('add')
    void $emitter.emit('wishlist-product-add', { id, price })
  }

  async function remove() {
    await request('remove')
  }

  function changeStatus(newStatus: Status, value = true) {
    resetStatus()
    status.value = newStatus
    if (newStatus === 'error')
      error.value = value

    else if (newStatus === 'initial')
      initial.value = value

    else if (newStatus === 'loading')
      loading.value = value

    else
      success.value = value
  }

  function resetStatus() {
    initial.value = false
    loading.value = false
    success.value = false
    error.value = false
  }

  return {
    active,
    product,
    add,
    remove,
    status,
    initial,
    loading,
    success,
    error,
  }
}
