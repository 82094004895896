import { AxiosError } from 'axios'
import { useAddressStore } from '~~/store/address'
import { useCartStore } from '~~/store/cart'
import { useChooseAddressStore } from '~~/store/modal/chooseAddress'
import type { Product } from '~~/type/product/Product'
import { ErrorSerializer } from '~~/serializer/Error'

type Status = 'loading' | 'error' | 'initial' | 'success'

export function useActionCart(id: number, {
  errorType = 'simple',
  onLoading = () => {},
  onSuccess = () => {},
  // возвращать сериализованную и не сериализованную ошибку
  onError = () => {},
}: {
  errorType?: 'simple' | 'base'
  onLoading?: () => void
  onSuccess?: ({ data }: { data: Product }) => void
  onError?: (e: unknown) => void
} = {}) {
  const { $error, $api, $emitter } = useNuxtApp()
  const cartStore = useCartStore()
  const addressStore = useAddressStore()
  const chooseAddressStore = useChooseAddressStore()
  const success = ref(false)
  const error = ref(false)
  const loading = ref(false)
  const initial = ref(true)
  const status = ref<Status>('initial')
  const active = computed(() => cartStore.isProductExistByID(id))
  const quantity = computed(() => cartStore.getProductQuantityByID(id))
  const product = computed(() => cartStore.getProductByID(id))
  const totalPrice = computed(() => (product.value?.price?.common?.value || 0) * quantity.value)

  async function request(type: 'add' | 'remove', quantity = 1) {
    changeStatus('loading')
    onLoading()

    try {
      if (type === 'remove')
        cartStore.showOverlay = true
      const response = await $api.cart[type]({ id, quantity })
      cartStore.setCart(response.result)
      const product = response.result.product[id]!
      changeStatus('success')
      onSuccess({ data: product })
      return product
    }
    catch (error) {
      changeStatus('error')
      onError(error)

      if (error instanceof AxiosError)
        new $error[errorType](ErrorSerializer(error))
      else
        throw error
    }
    finally {
      cartStore.showOverlay = false
    }
  }

  async function add(quantity = 1, listName = '') {
    function emit(product: Product) {
      void $emitter.emit('cart-product-add', { product: { ...product, quantity }, cart: cartStore.state!, listName })
    }
    if (!addressStore.isCurrentExist) {
      chooseAddressStore.enable({
        async afterAddressSelect() {
          const product = await request('add', quantity)
          if (product)
            emit(product)
        },
      })
    }
    else {
      const product = await request('add', quantity)
      if (product)
        emit(product)
    }
  }

  async function remove(quantity = 1) {
    const product = await request('remove', quantity)
    if (product)
      void $emitter.emit('cart-product-remove', { product: { ...product, quantity }, cart: cartStore.state! })
  }

  function increaseAmount({ listName }: { listName?: string }) {
    void add(quantity.value + 1, listName)
  }

  function decreaseAmount() {
    void (quantity.value > 1 ? add(quantity.value - 1) : remove())
    if (quantity.value > 1 && product.value)
      void $emitter.emit('cart-product-remove', { product: { ...product.value, quantity: 1 }, cart: cartStore.state! })
  }

  function changeStatus(newStatus: Status, value = true) {
    resetStatus()
    status.value = newStatus
    if (newStatus === 'error')
      error.value = value

    else if (newStatus === 'initial')
      initial.value = value

    else if (newStatus === 'loading')
      loading.value = value

    else
      success.value = value
  }

  function resetStatus() {
    initial.value = false
    loading.value = false
    success.value = false
    error.value = false
  }

  return {
    active,
    quantity,
    totalPrice,
    product,
    add,
    remove,
    increaseAmount,
    decreaseAmount,
    status,
    initial,
    loading,
    success,
    error,
  }
}
