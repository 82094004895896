import { defineStore } from 'pinia'

export const useFavoriteStore = defineStore('favorite', () => {
  const state = ref<{ [key: number]: boolean }>({})

  const list = computed(() => state.value)
  const amount = computed(() => Object.keys(state.value).length)
  /**
   * Строка из id товаров добавленных в избранное разделённая запятыми для запросов
   */
  const favoriteIds = computed(() => Object.keys(state.value).join(','))

  function isExist(id: number) {
    return !!state.value[id]
  }

  function getProduct(id: number) {
    return state.value[id] || null
  }

  function add({ id, payload = true }: { id: number; payload?: boolean }) {
    state.value = { ...state.value, [id]: payload }
  }

  function remove({ id }: { id: number }) {
    const local = state.value
    delete local[id]
    state.value = local
  }

  function setList({ list }: { list: { [key: number ]: boolean } }) {
    state.value = list
  }

  function $reset() {
    state.value = {}
  }

  return {
    state, list, amount, favoriteIds, add, $reset, isExist, getProduct, setList, remove,
  }
}, {
  persist: true,
  share: {
    enable: true,
  },
})
