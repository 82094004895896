<script setup lang="ts">
import { UI05StatusBlock, UI05Tooltip } from 'ui-05'
import { storeToRefs } from 'pinia'
import { declination } from '@artmizu/utils'
import { useOrderStore } from '~/store/order'
import { useProductStore } from '~/store/modal/product'
import { useConfirmStore } from '~/store/modal/confirm'
import { useOrderModalStore } from '~/store/modal/order'
import { useCartStore } from '~/store/cart'
import { isAllowCloseOrder } from '~/helper/orderShowFilters'
import type { Product } from '~/type/product/Product'

const emit = defineEmits<{ (e: 'close'): void }>()
const { $api, $emitter } = useNuxtApp()

const courierPhone = ref('')
const products = ref<Product[]>([])
const phone = shallowRef()
const { active: isShowOrder } = storeToRefs(useOrderModalStore())
const { list: userCart } = storeToRefs(useCartStore())
const { active: isOrderCancellingModalActive } = storeToRefs(useConfirmStore())

const order = computed(() => useOrderModalStore().data!)
const descCondition = computed(() => {
  return order.value?.status?.type === 'accepted' || order.value?.status?.type === 'collect' || order.value?.status?.type === 'transit'
})
const orderRepeat = computed(() => {
  return order.value?.products?.filter(el => el?.status?.code !== 'NOT_AVAILABLE')
})
const deliveryTime = computed(() => {
  return order.value.delivery?.time ? ` ${order.value.delivery?.time} ${order.value.delivery?.time && declination(order.value.delivery.time, ['минуту', 'минуты', 'минут'])}` : undefined
})
const orderText = computed(() => (order.value.status.type === 'completed' && order.value.leaveAtTheDoor) ? 'Ваш заказ был оставлен у двери' : 'Ваш заказ')

const { addToDisableOrdersList } = useOrderStore()
const { enable: enableProduct } = useProductStore()
const { enable: enableConfirm, disable: disableConfirm } = useConfirmStore()
const { enable: enableOrder, disable: disableOrder, setCancelledStatus } = useOrderModalStore()
const { setCart } = useCartStore()

const syncApi = useActionEntity($api.cart.sync, {
  params: {},
  onSuccess: ({ data }) => {
    setCart(data.result)
    UI05Tooltip({
      type: 'success',
      title: 'Отлично!',
      description: 'Добавлены все товары, которые есть в наличии',
      mobileOffset: [5, 5, 63, 5],
    })
    /**
      * закрываем окно через 2 секунды, чтобы пользователь мог понять что произошло
    */
    setTimeout(() => {
      emit('close')
    }, 2000)
  },
  onError: () => {
    UI05Tooltip({ title: 'Произошла ошибка', description: 'при добавлении товаров в корзину' })
  },
})

const removeOrderApi = useActionEntity($api.order.remove, {
  params: { id: order.value?.id },
  onSuccess: () => {
    disableConfirm()
    useOrderModalStore().onAccess?.callback()
  },
  onError: ({ serialized }) => {
    UI05Tooltip({ title: 'Произошла ошибка при отмене заказа', description: serialized?.description })
  },
})

function onCloseClick() {
  if (order.value && isAllowCloseOrder(order.value?.status?.type))
    addToDisableOrdersList(order.value?.id)

  disableOrder()
}

function onOverlayClick(payload: Event) {
  const isOverlay = (payload.target as HTMLElement).classList.contains('order-status__overlay')
  if (isOverlay)
    disableOrder()
}

function onCall() {
  if (order.value?.courierPhone) {
    courierPhone.value = order.value.courierPhone[0]
    phone.value.click()
  }
  $emitter.emit('status-order-click-call-the-courier', { status: order.value.status.name })
}

function onProductClick({ id, path }: { id: number; path?: string }) {
  disableOrder()
  enableProduct({ active: true, id, path })
}

function addToCart(data: { product: Product[] }) {
  /**
    * Т.к. во фронте в корзине даркстора не добавлен роут синхронизации,
    * чтобы при повторе заказа не удались товары, которые были в корзине прежде,
    * получаем товары с заказа и товары с pinia и делаем запрос синхронизации
  */
  data.product.length
    ? syncApi.request({ data: data.product, cart: userCart.value })
    : UI05Tooltip({
      type: 'warning',
      title: 'Невозможно',
      description: 'Этого товара нет в наличии ):',
      mobileOffset: [5, 5, 63, 5],
    })
  products.value = data.product
}
</script>

<template>
  <transition
    :duration="{ enter: 400, leave: 400 }"
    enter-from-class="fade-in-up-enter"
    enter-active-class="fade-in-up-enter-active"
    enter-to-class="fade-in-up-enter-to"
    leave-from-class="fade-out-down-leave"
    leave-active-class="fade-out-down-leave-active"
    leave-to-class="fade-out-down-leave-to"
  >
    <div
      v-if="isShowOrder"
      class="order-status__overlay"
      @click="onOverlayClick"
    >
      <div class="order-status__modal">
        <div class="order-status__modal-head">
          <div class="order-status__number">
            Заказ №{{ order.number }}
          </div>
          <div class="order-status__title" :class="[{ 'order-status__title_margin': !descCondition }]">
            {{ order.status && order.status.name }}
          </div>
          <div
            v-if="order.status"
            class="order-status__modal-close"
            @click="onCloseClick"
          />
          <div :class="`order-status__img-wrap order-status__img-wrap_${order.status.type}`">
            <div class="order-status__img" />
          </div>
          <div
            v-if="descCondition && order.delivery?.time"
            class="order-status__description"
          >
            {{ `Доставим через ~${deliveryTime}` }}
          </div>
          <div
            v-else-if="order.status && order.status.type === 'not-paid'"
            class="order-status__description"
          >
            {{ 'Оплата не прошла, повторите снова' }}
          </div>
          <div v-if="order.isDelay" class="order-status__changes">
            Заказ задерживается
          </div>
          <div v-else-if="order.isChanged" class="order-status__changes">
            В заказе есть изменения
          </div>
          <div v-else-if="order.status.type === 'cancelled' && order.payResult" class="order-status__changes">
            {{ order.payResult }}
          </div>
          <div class="order-status__actions-block">
            <a
              :href="order.supportLink"
              target="_blank"
              class="order-status__action"
              @click="$emitter.emit('status-order-click-support-chat', { status: order.status.name })"
            >
              <div class="order-status__action-img order-status__action-img_chat" />
              <div class="order-status__action-text">Чат поддержки</div>
            </a>
            <div
              v-if="order.status && order.status.type !== 'not-paid'"
              class="order-status__action"
              @click="
                addToCart({ product: orderRepeat }),
                disableOrder(),
                $emitter.emit('status-order-click-repeat-order', { status: order.status.name })
              "
            >
              <div class="order-status__action-img order-status__action-img_repeat-order" />
              <div class="order-status__action-text">
                Повторить заказ
              </div>
            </div>
            <a
              v-else
              :href="order.link"
              class="order-status__action"
              target="_blank"
              @click="$emitter.emit('status-order-click-pay-for-the-order', { status: order.status.name })"
            >
              <div class="order-status__action-img order-status__action-img_pay-order" />
              <div class="order-status__action-text">Оплатить заказ</div>
            </a>
            <div
              v-if="
                order.status && (order.status.type === 'accepted' || order.status.type === 'collect' || order.status.type === 'not-paid')
              "
              class="order-status__action"
              @click="
                disableOrder(),
                enableConfirm({
                  title: 'Отменить заказ?',
                  desc: 'После отмены содержимое заказа автоматически сбросится',
                  onConfirm: async () => {
                    await removeOrderApi.request({ id: order.id })
                    !isOrderCancellingModalActive && setCancelledStatus()
                    $emitter.emit('status-order-click-cancel-the-order', { status: order.status.name })
                  },
                  onReject: () => {
                    disableConfirm()
                    enableOrder({ data: order })
                  },
                })
              "
            >
              <div class="order-status__action-img order-status__action-img_cancelled-order" />
              <div class="order-status__action-text">
                Отменить заказ
              </div>
            </div>
            <div
              v-if="order.status && order.status.type === 'transit' && order.courierPhone"
              class="order-status__action"
              @click="onCall"
            >
              <div class="order-status__action-img order-status__action-img_call-courier" />
              <div class="order-status__action-text">
                Звонок курьеру
              </div>
            </div>
          </div>
        </div>
        <div class="order-status__modal-body">
          <div class="order-status__address-block">
            <div class="order-status__address-block-title">
              Адрес доставки
            </div>
            <div class="order-status__address">
              {{ order.delivery.detail?.address }}
            </div>
          </div>
          <div class="order-status__order-block">
            <div class="order-status__order-title">
              <span class="order-status__order-title-text">{{ orderText }}</span>
              <Price
                v-if="order.price"
                :price="order.price"
                size="big"
                :changed-price="order.changedPrice"
              />
            </div>
            <template v-if="order.products">
              <OrderProduct
                v-for="item in order.products"
                :key="item.id"
                class="mb-10"
                :product="item"
                :is-changed="order.isChanged"
                @product-click="onProductClick"
              />
            </template>
          </div>
        </div>
      </div>
      <a
        ref="phone"
        :href="`tel:${courierPhone}`"
      />
    </div>
    <div v-else-if="removeOrderApi.error.value">
      <UI05StatusBlock
        size="m"
        type="error"
        margin="auto"
        title="При отмене заказа произошла ошибка"
      />
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.order-status {

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-above-modal);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* Оставляем 100vh, на случай, если не сработает -webkit-fill-available */
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding-right: 20px;
    margin-right: -20px;
    overflow-y: scroll;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1;
    will-change: transform, opacity;
  }

  &__modal {
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    max-height: 90vh;
    margin: auto;
    overflow-y: scroll;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__modal-head {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 0;
    background-image: linear-gradient(90deg, #ededff 0%, rgba(250, 230, 237, 0.44) 100%);
    background-repeat: no-repeat;
    background-size: 100% 110px;
  }

  &__number {
    font-size: 16px;
    line-height: 18px;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;

    &_margin {
      margin: 0;
    }
  }

  &__img-wrap {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: 0 auto 10px;
    background-color: #ffc531;
    border: 5px solid #fff;
    border-radius: 50%;

    &_accepted {

      ^^&__img {
        width: 17px;
        height: 11px;
        background-image: svg-load('./asset/order-accepted-icon.svg', fill=transparent);
      }
    }

    &_collect {

      ^^&__img {
        width: 26px;
        height: 26px;
        background-image: svg-load('./asset/order-cart-icon.svg', fill=transparent);
      }
    }

    &_transit {

      ^^&__img {
        width: 29px;
        height: 29px;
        background-image: svg-load('./asset/order-transit-icon.svg', fill=transparent);
      }
    }

    &_completed {

      ^^&__img {
        width: 26px;
        height: 26px;
        background-image: svg-load('./asset/order-completed-icon.svg', fill=transparent);
      }
    }

    &_not-paid {
      background-color: #e30613;

      ^^&__img {
        width: 25px;
        height: 25px;
        background-image: svg-load('./asset/order-not-paid-icon.svg', fill=transparent);
      }
    }

    &_cancelled {
      background-color: #e30613;

      ^^&__img {
        width: 20px;
        height: 20px;
        background-image: svg-load('./asset/order-cancelled-icon.svg', fill=transparent);
      }
    }
  }

  &__img {
    background-position: center;
  }

  &__changes {
    padding: 6px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #E30613;
    border-radius: 4px;
  }

  &__description {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #9b9b9b;
    text-align: center;
  }

  &__actions-block {
    display: flex;
    justify-content: center;
    margin: 15px 0 40px;
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 75px;
    margin-right: 20px;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }
  }

  &__action-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #f7f7f7;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;

    &_chat {
      background-image: svg-load('./asset/chat.svg', fill=transparent);
    }

    &_repeat-order {
      background-image: svg-load('./asset/repeat-order.svg', fill=transparent);
    }

    &_pay-order {
      background-image: svg-load('./asset/pay-order.svg', fill=transparent);
    }

    &_cancelled-order {
      background-image: svg-load('./asset/cancelled-order.svg', fill=transparent);
    }

    &_call-courier {
      background-image: svg-load('./asset/call-courier.svg', fill=transparent);
    }
  }

  &__action-text {
    font-size: 14px;
    line-height: 16px;
    color: #818181;
    text-align: center;
  }

  &__address-block {
    margin-bottom: 30px;
  }

  &__address-block-title,
  &__order-title {
    padding-bottom: 15px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    border-bottom: 1px solid #f2f2f2;
  }

  &__address {
    font-size: 16px;
    line-height: 20px;
  }

  &__order-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__modal-close {
    position: absolute;
    top: 32px;
    right: 30px;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    cursor: pointer;
    background-image: svg-load('./asset/close.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 12px;

    &:hover {
      background-image: svg-load('./asset/close.svg', fill=#ee272d);
      transition: background-image 0.2s ease-out;
    }
  }

  &__modal-body {
    padding: 0 25px 25px;
  }

  @media (max-width: 900px) {

    &__overlay {
      padding-top: 70px;
    }

    &__modal {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      min-height: 100%;
      background-color: #f5f5f5;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.08);
    }

    &__modal-head {
      flex-shrink: 0;
      padding: 25px;
      margin-bottom: 5px;
      background-color: #fff;
      background-image: none;
      border-radius: 20px;
    }

    &__modal-body {
      flex-grow: 1;
      padding: 0 0 15px;
    }

    &__modal-close {
      top: 20px;
      right: 15px;
    }

    &__number {
      order: 1;
      margin-bottom: 20px;
    }

    &__img-wrap {
      order: 2;
      margin-bottom: 5px;
      border: 0;
    }

    &__title {
      order: 3;
      margin-bottom: 5px;
      text-align: center;

      &_margin {
        margin-bottom: 20px;
      }
    }

    &__changes {
      order: 5;
    }

    &__description {
      order: 4;
    }

    &__actions-block {
      order: 5;
      margin: 15px 0 0;
    }

    &__address-block {
      padding: 15px;
      margin-bottom: 5px;
      background-color: #fff;
      border-radius: 20px;
    }

    &__order-block {
      padding: 15px;
      background-color: #fff;
      border-radius: 20px;
    }
  }

  @media (max-width: 800px) {

    &__modal {
      margin: initial;
      /* Стиль для того чтобы прибивать модалки к низу на мобильном разрешении */
      margin-top: auto;
    }
  }
}

/* Кастомная анимация появления */
@keyframes customFadeInUp {

  from {
    transform: translateY(70px);
  }

  to {
    transform: translateY(0);
  }
}

/* Кастомная анимация исчезновения */
@keyframes customFadeOutDown {

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(70px);
  }
}

.fade-out-down-leave-active,
.fade-in-up-enter-active {
  transition: opacity 0.4s;

  .order-status__modal {
    animation: 0.3s customFadeInUp;
  }
}

.fade-in-up-enter,
.fade-out-down-leave-active {
  transition: opacity 0.5s;

  .order-status__modal {
    animation: 0.5s customFadeOutDown;
  }
}
</style>
