interface Selection {
  active: boolean
  id: number
  img: string
  name: string
}

export default function (el: { [key: string]: any }): Selection {
  return {
    active: el.ACTIVE,
    id: +el.ID,
    img: el.IMAGE,
    name: el.NAME,
  }
}
