<script setup lang="ts">
import { UI05Modal } from 'ui-05'
import { useChooseProject } from '~/store/modal/chooseProject'

const chooseProjectStore = useChooseProject()
</script>

<template>
  <UI05Modal
    max-width="514px"
    :show="chooseProjectStore.active"
    @close="chooseProjectStore.disable"
  >
    <template #title>
      <h3 class="choose-project__title">
        Наши проекты
      </h3>
    </template>
    <div class="choose-project__list">
      <nuxt-link
        to="/"
        class="choose-project__item"
        @click="chooseProjectStore.disable"
      >
        <div class="choose-project__item-col">
          <div class="choose-project__item-title">
            Даркстор
          </div>
          <div class="choose-project__item-desc">
            Доставка продуктов за 30 минут
          </div>
        </div>
        <div class="choose-project__item-col">
          <div class="choose-project__item-logo">
            <img
              class="choose-project__item-img choose-project__item-img_darkstore-logo"
              src="./asset/logo.svg"
              alt="05.ру DarkStore доставка продуктов"
            >
          </div>
        </div>
      </nuxt-link>
      <a
        href="https://05.ru/"
        class="choose-project__item"
        @click="chooseProjectStore.disable"
      >
        <div class="choose-project__item-col">
          <div class="choose-project__item-title">Интернет-магазин</div>
          <div class="choose-project__item-desc">Электроника и бытовая техника</div>
        </div>
        <div class="choose-project__item-col">
          <div class="choose-project__item-logo">
            <img
              class="choose-project__item-img"
              src="./asset/logo.png"
              alt="05.ру интернет-магазин бытовой техники"
            >
          </div>
        </div>
      </a>
    </div>
  </UI05Modal>
</template>

<style lang="postcss" scoped>
.choose-project {

  &__title {
    font-size: 29px;
    font-weight: 500;
    line-height: 26px;
  }

  &__list {
    margin-top: 14px;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 14px 50px 14px 25px;
    margin-bottom: 15px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #ededed;
    border-radius: 5px;

    &:hover {
      border-color: #e30613;
      transition: border-color 0.2s ease-out;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      position: absolute;
      top: calc(50% - 6px);
      right: 25px;
      width: 7px;
      height: 12px;
      content: '';
      background: svg-load('./asset/arrow-right.svg', fill=#000) no-repeat center;
      background-size: contain;
    }

    &-col:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 12px;
    }

    &-col:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }

    &-logo {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      height: 50px;
    }

    &-img {
      max-width: 100%;
      max-height: 100%;

      &_darkstore-logo {
        width: 40px;
        height: 40px;
      }
    }

    &-title {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
    }

    &-desc {
      font-size: 14px;
      line-height: 16px;
      color: #818181;
    }
  }
  @media (max-width: 800px) {

    &__title {
      font-size: 19px;
    }

    &__list {
      margin-top: -3px;
    }

    &__item {
      padding: 11px 31px 11px 9px;
      margin-bottom: 10px;

      &:after {
        right: 9px;
        content: '';
      }

      &-title {
        margin-bottom: 2px;
        font-size: 16px;
        line-height: 18px;
      }

      &-desc {
        font-size: 12px;
        line-height: 14px;
      }

      &-logo {
        width: 50px;
        height: 36px;
      }
    }
  }
}
</style>
