import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useOrderStore = defineStore('order', () => {
  const disableOrdersList = ref<string[]>([])

  function addToDisableOrdersList(orderID: string) {
    if (!disableOrdersList.value.includes(orderID))
      disableOrdersList.value.push(orderID)
  }

  function removeFromDisableOrdersList(orderID: string) {
    if (disableOrdersList.value.includes(orderID))
      disableOrdersList.value = disableOrdersList.value.filter(el => el !== orderID)
  }

  return { disableOrdersList, addToDisableOrdersList, removeFromDisableOrdersList }
}, {
  persist: true,
})
