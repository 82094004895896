import { defineStore } from 'pinia'
import { useCartStore } from '~/store/cart'

export const useCartChangedModal = defineStore('cartChangedModal', () => {
  const cartStore = useCartStore()

  const active = ref(false)
  const isChecked = ref(false)

  function enable() {
    active.value = true
  }

  async function disable() {
    const { $api, $error } = useNuxtApp()
    try {
      const response = await $api.cart.sync({
        cart: cartStore.list,
      })

      if (process.client) {
        requestAnimationFrame(() => {
          cartStore.setCart(response.result)
          setChecked(true)
        })
      }
    }
    catch (e) {
      new $error.page({
        message: 'Ошибка при синхронизации корзины, попробуйте перезагрузить страницу',
      })
    }
    active.value = false
  }

  function setChecked(value: boolean) {
    isChecked.value = value
  }

  return { active, isChecked, enable, disable, setChecked }
})
