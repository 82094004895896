<script setup lang="ts">
import { UI05Button, UI05StatusBlock } from 'ui-05'
import { declination } from '@artmizu/utils'
import { storeToRefs } from 'pinia'
import { useProductStore } from '~/store/modal/product'
import { useSubCategoriesStore } from '~/store/subCategory'
import type { Product } from '~/type/product/Product'
import type Pagination from '~/type/Pagination'
import type { Category } from '~/type/Category'
import type Sort from '~/type/Sort'
import { useAddressStore } from '~/store/address'
import { useChooseAddressStore } from '~/store/modal/chooseAddress'
import { useSearchMobileStore } from '~/store/modal/searchMobile'

const props = defineProps<{
  apiResponse: Awaited<ReturnType<typeof useDataEntityList<any, {
    list: Product[]
    sort: Sort[]
    pagination: Pagination
    category?: Category[]
  }>>>
  activeQuery?: string
  currentCategory?: Category
}>()
const emit = defineEmits<{
  (e: 'load-more'): void
  (e: 'category-click', category?: Category): void
}>()
const route = useRoute()
const router = useRouter()

const { enable: enableProduct } = useProductStore()
const { current } = storeToRefs(useAddressStore())
const { enable: chooseAddressMapEnable } = useChooseAddressStore()
const { disable: disableMobileSearch } = useSearchMobileStore()
const subCategoryStore = useSubCategoriesStore()

const queryString = computed(() => route.query?.q ?? props.activeQuery)
const searchResultTitle = computed(() => `По запросу «${queryString.value}» найдено ${props.apiResponse.pagination.value.itemQuantity} ${declination(props.apiResponse.pagination.value.itemQuantity, ['товар', 'товара', 'товаров'])}`)

onUnmounted(() => subCategoryStore.setCategories([]))

function reload() {
  location.reload()
}

function onClose() {
  router.push('/')
  disableMobileSearch()
}
</script>

<template>
  <div>
    <UI05StatusBlock
      v-if="!apiResponse.list?.value?.length && apiResponse.loading.value"
      key="loading"
      size="l"
      type="loading"
      class="search-page__loading"
    />
    <UI05StatusBlock
      v-else-if="apiResponse.error.value"
      key="error"
      size="l"
      type="error"
    >
      <div class="search-page__error-wrapper">
        <p class="p">
          Попробуйте воспользоваться поиском позднее или обновить страницу
        </p>
        <div class="search-page__button">
          <UI05Button
            fluid
            size="39"
            theme="white"
            @click="reload"
          >
            Обновить страницу
          </UI05Button>
        </div>
      </div>
    </UI05StatusBlock>
    <div
      v-else-if="!apiResponse.list?.value?.length && queryString"
      class="search-page__empty"
    >
      <div class="search-page__empty-content">
        <h4 class="h4 mb-12 fz-24 search-page__empty-title">
          По вашему запросу ничего не найдено
        </h4>
        <p class="p mb-20 search-page__empty-desc">
          Измените запрос или поищите в другом разделе
        </p>
        <button
          fluid
          class="search-page__button"
          size="49"
          @click="onClose"
        >
          Перейти в каталог
        </button>
      </div>
    </div>
    <div v-show="apiResponse.list?.value?.length">
      <ProductGrid
        v-if="apiResponse.list?.value?.length"
        :loading="apiResponse.loading.value"
        :list="apiResponse.list.value"
        :quantity-on-page="25"
        intersection
        @intersect="emit('load-more')"
      >
        <template #header>
          <h2 class="h2 search-page__title">
            {{ searchResultTitle }}
          </h2>
          <CategoryList
            v-if="subCategoryStore.categories.length"
            :list="subCategoryStore.categories"
            :current-category="currentCategory"
            class="mt-15"
            @click="emit('category-click', $event)"
          />
        </template>
        <template #product="{ product }">
          <ProductCard
            :key="product.id"
            :disabled-photo-lazyload="false"
            :product="product"
            list-name="Результаты поиска"
            @click="enableProduct({ active: true, id: product.id, path: product.path })"
          />
        </template>
      </ProductGrid>
      <DemoNote
        class="search-demo-note"
        :active="!current"
        @open-map="chooseAddressMapEnable"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.search-page {
  padding-top: 7px;

  &__title {
    margin-bottom: 15px;
  }

  &__error-wrapper {
    max-width: 310px;
    margin: 0 auto;
  }

  &__button {
    padding: 17px 40px;
    color: white;
    cursor: pointer;
    background-color: #E30613;
    border: none;
    border-radius: 10px;
  }

  &__loading {
    margin-top: 100px !important;
  }

  &__empty {
    width: 100%;
    max-width: 526px;
    padding-top: 23px;
    margin: 0 auto;

    &-content {
      margin-right: 40px;
    }

    &-categories {

      &-list {
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        padding: 5px 10px;
        margin: 0 10px 8px 0;
        background-color: #fffae5;
        border: 1px solid #efe9cc;
        border-radius: 21px;
      }

      &-buttons {
        display: flex;
        align-items: center;
      }
    }

    &-filter {
      position: relative;
      width: 100%;
      padding: 17px 23px 22px;
      overflow: hidden;
      border: 1px solid #f2f2f2;
      border-radius: 5px;

      &-block {
        width: 100%;
        max-width: 225px;
      }

      &-bg {
        position: absolute;
        top: 5px;
        right: 15px;
        width: 188px;
        height: 145px;
        background-image: url(./asset/pad.png);
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: contain;
      }
    }
  }
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    &__title {
      display: none;
      margin-bottom: 16px;
      font-size: 26px;
      line-height: 31px;
    }

    &__grid {
      order: 3;
      width: 100%;
      margin-left: 0px;
    }
  }
  @media (max-width: 800px) {

    &__empty {
      padding-top: 15px;

      &-content {
        margin-right: 0;
      }

      &-desc {
        margin-bottom: 18px;
      }

      &-categories {
        margin-bottom: 21px;

        &-title {
          margin-bottom: 10px;
        }

        &-buttons {
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
        }

        &-link,
        &-button {
          width: 100%;
        }
      }

      &-filter {
        padding: 15px 17px 19px;

        &-block {
          max-width: 200px;
        }

        &-bg {
          top: -1px;
          right: -3px;
          width: 122px;
          height: 150px;
          background-image: url(./asset/pad-mobile.png);
        }
      }
    }
  }
}

.search-demo-note {
  @media (max-width: 800px) {
    position: sticky;
    left: 0;
    min-width: 90%;
    transform: none;
  }
}
</style>
