/**
 * Плагин управляет конфигурацией axios'а для работы с API 05
 * он занимается простановкой базового УРЛа, обновление токенов
 *
 * Также тут можно найти прочую логику которая важна для других частей
 * приложения и которая связана с АПИ
 */

import axios from 'axios'
import axiosRetry from 'axios-retry'
import { buildMemoryStorage, setupCache } from 'axios-cache-interceptor'
import { stringify } from 'qs'
import { AWSXRayIdGenerator } from '@opentelemetry/id-generator-aws-xray'
import { TokenCheck, TokenError } from './TokenCheck'
import { defineNuxtPlugin } from '#app'

import { useServiceStore } from '~~/store/service'
import getAPIList from '~/api/index'
import { useTokenStore } from '~/store/token'
import { useUserStore } from '~/store/user'
import TokenSerializer from '~/serializer/Token'
import type { Token } from '~/type/Token'
import { useAddressStore } from '~/store/address'

export default defineNuxtPlugin((nuxtApp) => {
  const dateStore = useServiceStore()
  const userStore = useUserStore()
  const tokenStore = useTokenStore()
  const addressStore = useAddressStore()
  const api = getAPIList()
  const runtimeConfig = useRuntimeConfig()
  const mindboxId = useCookie('mindboxDeviceUUID')
  const headers = useRequestHeaders()
  const route = useRoute()

  const idGenerator = new AWSXRayIdGenerator()
  let baseRequestUrl = route.path

  if (headers.traceparent && !Array.isArray(headers.traceparent))
    dateStore.setTraceParent(headers.traceparent)

  const instance = setupCache(axios.create({
    baseURL: runtimeConfig.public.baseUrl,
    timeout: 20000,
    headers: {
      'X-SHOP-ID': 'DARKSTORE',
      'post': {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      // передаем для бэка ключ mindbox из кук
      'Device-Uuid': mindboxId.value ?? '',
    },
  }), {
    ttl: 0,
    storage: buildMemoryStorage(true),
  })

  axiosRetry(instance, {
    retries: 1,
  })

  async function getBaseToken(
    { clientID, clientSecret }: { clientID: string; clientSecret: string },
  ) {
    const { data } = await instance.post(
      '/api/v2/oauth/token',
      stringify({
        grant_type: 'client_credentials',
        client_id: clientID,
        client_secret: clientSecret,
      }),
      {
        params: {
          isTokenPath: true,
        },
      },
    )

    return TokenSerializer(data.result)
  }

  async function refreshToken(
    { refreshToken }: { refreshToken: string },
  ) {
    const { data } = await instance.post(
      '/api/v2/oauth/token',
      stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
      {
        params: {
          isTokenPath: true,
        },
      },
    )
    return TokenSerializer(data.result)
  }

  instance.interceptors.request.use(async (config) => {
    /*  Добавляем трассировку. Получаем traceparent из заголовков nginx и сохраняем его в store.
      * После этого, добавляем traceparent в заголовки при каждом запросе axios. При этом, при каждой смене
      * роута, мы генерируем новый TraceId и обновляем его в TraceParent
    */
    const traceIdRegEx = /[^-]*[^-]/g
    let traceparent = dateStore.traceParent
    const traceParentMatch = traceparent?.match(traceIdRegEx)

    if (baseRequestUrl !== route.path && traceParentMatch && traceParentMatch[1]) {
      const newTraceId = idGenerator.generateTraceId()
      baseRequestUrl = route.path
      traceparent = dateStore.traceParent?.replace(traceParentMatch[1], newTraceId)
      if (traceparent)
        dateStore.setTraceParent(traceparent)
    }

    if (traceparent)
      config.headers.traceparent = traceparent

    /**
     * Если это не запрос к АПИ 05 или это запрос за обновление токенов - больше ничего не делаем
     */
    const notAPIRequest = config.url?.[0] !== '/' && !config.url?.includes(config.baseURL!)
    const isTokenPath = config.params?.isTokenPath
    if (notAPIRequest || isTokenPath)
      return config

    try {
      const token = await TokenCheck({
        token: tokenStore.token,
        requestRefreshAccess(token) {
          return refreshToken({ refreshToken: token })
        },
        async requestBaseToken() {
          return getBaseToken({
            clientID: runtimeConfig.clientId,
            clientSecret: runtimeConfig.clientSecret,
          })
        },
        onTokenReset() {
          tokenStore.$reset()

          if (userStore.isUser)
            userStore.$reset()
        },
      })

      tokenStore.set(token)
      config.headers.Authorization = `Bearer ${token.accessToken.value}`
    }
    catch (e) {
      if (e instanceof TokenError) {
        tokenStore.$reset()

        if (userStore.isUser)
          userStore.$reset()

        throw new nuxtApp.vueApp.$nuxt.$error.page(e)
      }
      else {
        throw e
      }
    }

    return config
  })

  let refreshTokenPromiseResponse: Promise<Token> | null = null

  instance.interceptors.response.use((response) => {
    /**
     * TODO Проверить что response.cached поля отрабатывает правильно, не уверен
     */
    console.log('cached', response.config.url, response.cached)

    /**
     * Проверяем состояние сервиса (Y - доступен, N - недоступен).
     * Так как заголовки кэшированного запрос не меняются, смотрим, если запрос из кэша, то заголовки этого запроса не проверяем,
     * так как значение свойства "x-dakstore-available" может отличится от значения в админке и не поменяется состояние сервиса
     */
    if (!response.cached) {
      if (response.headers['x-dakstore-available'] === 'Y')
        dateStore.setServiceUnavailable(false)
      else if (response.headers['x-dakstore-available'] === 'N')
        dateStore.setServiceUnavailable(true)
    }

    return response
  }, async (error) => {
    const notAPIResponse
        = error?.response?.config?.url[0] !== '/' && String(!error?.response?.config?.url).includes(error?.response?.config?.baseURL)
    if (notAPIResponse)
      return Promise.reject(error)

    const originalRequest = error.config

    if (error?.response?.status === 424 && error?.response.data.error.message === 'invalid_refresh_token') {
      userStore.$reset()
      tokenStore.$reset()
      new nuxtApp.vueApp.$nuxt.$error.page({
        message: 'Невалидный refresh токен',
        code: 503,
        native: error,
      })
      return Promise.reject(error)
    }

    if (error?.response?.status === 401 && error?.response.data.error.message === 'user_not_authorized') {
      tokenStore.$reset()
      userStore.$reset()

      if (process.client)
        window.location.replace('/')

      else
        await navigateTo('/')

      return Promise.reject(error)
    }

    if (error?.response?.status === 406 && error?.response.data.error.message === 'delivery_area_null' && addressStore.current) {
      try {
        await api.map.setAddress(addressStore.current)
        return instance.request(originalRequest)
      }
      catch (e) {
        throw new nuxtApp.vueApp.$nuxt.$error.base({
          message: 'Ошибка при попытке повторной простановки адреса',
          code: 503,
          native: e,
        })
      }
    }

    if (error?.response?.status === 401 && error?.response.data.error.message === 'unauthorized' && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        if (!refreshTokenPromiseResponse)
          refreshTokenPromiseResponse = refreshToken({ refreshToken: tokenStore.token!.refreshToken.value }).finally(() => refreshTokenPromiseResponse = null)

        const token = await refreshTokenPromiseResponse
        tokenStore.set(token)
        return instance.request(originalRequest)
      }
      catch (e) {
        if (process.server) {
          try {
            const token = await getBaseToken({
              clientID: runtimeConfig.clientId,
              clientSecret: runtimeConfig.clientSecret,
            })
            tokenStore.set(token)
            userStore.$reset()
          }
          catch (e) {
            throw new nuxtApp.vueApp.$nuxt.$error.page({
              message: 'При запросе бэк вернул ошибку авторизации и после не удалось обновить базовый токен на сервере',
              code: 503,
              native: e,
            })
          }
        }
        else {
          throw new nuxtApp.vueApp.$nuxt.$error.page({
            message: 'При запросе бэк вернул ошибку авторизации и после не удалось обновить базовый токен на клиенте',
            code: 503,
            native: e,
          })
        }
      }
    }
    else if (originalRequest?._retry) {
      if (process.server) {
        try {
          const token = await getBaseToken({
            clientID: runtimeConfig.clientId,
            clientSecret: runtimeConfig.clientSecret,
          })
          tokenStore.set(token)
          userStore.$reset()
        }
        catch (e) {
          throw new nuxtApp.vueApp.$nuxt.$error.page({
            message: 'При запросе бэк вернул ошибку авторизации и после не удалось обновить базовый токен на сервере',
            code: 503,
            native: e,
          })
        }
      }
      else {
        throw new nuxtApp.vueApp.$nuxt.$error.page({
          message: 'При запросе бэк вернул ошибку авторизации и после не удалось обновить базовый токен на клиенте',
          code: 503,
          native: error,
        })
      }
    }

    new nuxtApp.vueApp.$nuxt.$error.base({
      message: 'Произошла ошибка при получении ответа на запрос с бэка',
      native: error,
    })

    return Promise.reject(error)
  })

  return {
    provide: {
      api,
      axios: instance,
    },
  }
})
