import Emittery from 'emittery'
import type { Cart } from '~/type/Cart'
import type { Product } from '~/type/product/Product'
import type { User } from '~/type/User'

export interface Events {
  /**
   * Событие выбора товара, внутри товар
   */
  'product-select': Product & { listName?: string }
  /**
   * Событие открытия оформления, внутри товары
   */
  'begin-checkout': { product: Product[]; currentValue: number }
  /**
   * Событие после оформления заказа авторизованным пользователем, внутри информация о заказе и пользователе
   */
  'after-checkout': {
    user: Partial<User>
    order: {
      id: string
      product: Product[]
      price: { total: number; delivery: number }
      paymentMethod: string | null
      coupon?: string
    }
  }
  /**
   * Событие изменения пользователем способа оплаты, внутри способ оплаты
  */
  'select-payment': string
  /**
   * Событие удаления пользователем товара в корзине
  */
  'cart-product-remove': { product: Product; cart?: Cart }
  /**
   * Событие удаления пользователем товара в корзине
  */
  'cart-product-remove-list': { list: Product[] }
  /**
   * Событие добавления пользователем товара в корзине
  */
  'cart-product-add': { product: Product; cart?: Cart; listName?: string }
  /**
   * Событие авторизации, содержит тип авторизации (пароль, смс) и телефон или почту
  */
  'auth': Partial<{
    phone: string
    activeTypeBlock: string
    username: string
    email: string
  }>
  /**
   * Событие просмотра какого-то списка товаров пользователем, внутри список товаров
   */
  'product-list-view': {
    list: Product[]
    listName: string
    product?: Product
  }
  /**
   * Событие просмотра пользователем карточки продукта, внутри данные продукта
   */
  'product-view': Partial<Product>
  /**
   * Событие при фидбеке пользователя, внутри данные продукта
   */
  'order-refund': {
    id: string
    product: Product[]
  }
  /**
   * Событие промокода, внутри его статус и цена скидки (чтобы проверить, применилась ли она)
  */
  'apply-promocode': { status: 'success' | 'error'; discount?: number }
  /**
   * Событие при выборе пользователем другого адреса
  */
  'user-select-another-address': string
  /**
   * Событие открытия модального окна выбора адреса
  */
  'choose-city-modal-enable': string
  /**
   * Событие при поиске
  */
  'search-product': string
  /**
   * Событие поиска товара
  */
  'search': { searchTerm: string }
  /**
   * Событие при неудачном результате поиска
  */
  'get-empty-search-result': string
  /**
   * Событие клика на подсказку при поиске
  */
  'click-search-link': string
  /**
   * Событие клика на баннер сторис
  */
  'click-story-banner': number
  // Событие просмотра категории
  'view-category': { id: string; name: string }
  // Событие добавления товара в избранное
  'wishlist-product-add': { id: number; price: number }
  /**
   * Событие уведомления о возможности доставки на адрес
  */
  'click-notify-delivery-available': never
  // Клик на отмену заказа в истории заказов
  'order-cancel-from-history': never
  // Клик на чат поддержки в модалке заказа
  'status-order-click-support-chat': { status: string }
  // Клик на повтор заказа в модалке заказа
  'status-order-click-repeat-order': { status: string }
  // Клик на отмену заказа в модалке заказа
  'status-order-click-cancel-the-order': { status: string }
  // Клик на звонок курьеру в модалке заказа
  'status-order-click-call-the-courier': { status: string }
  // Клик на оплату заказа в модалке заказа
  'status-order-click-pay-for-the-order': { status: string }
  // Событие открытия карты
  'open-modal-choose-address': never
}

export type EmitteryType = InstanceType<typeof Emittery<Events>>

export default defineNuxtPlugin((nuxtApp) => {
  const emitter = new Emittery<Events>()
  nuxtApp.vueApp.provide('emitter', emitter)

  return {
    provide: {
      emitter,
    },
  }
})
